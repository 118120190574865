import React, { useMemo } from "react";
import { IRadio } from "./radio";
import "./styles.scss";

let radioIdIndex = 0;
const getNextRadioIdIndex = () => {
  const result = radioIdIndex;
  radioIdIndex += 1;
  return result;
};

function ORadio(props: Readonly<IRadio>) {
  const { disabled, name, title, onClick, onChange, checked, type, value, id } =
    props;

  const internalId = useMemo(
    () => id || `${name}-${getNextRadioIdIndex()}`,
    [id, name]
  );

  return (
    <label
      className={`${checked ? "active" : ""} o-radio ${type ?? ""}`}
      htmlFor={internalId}
    >
      <input
        id={internalId}
        type="radio"
        disabled={disabled}
        checked={checked}
        name={name}
        onClick={onClick}
        onChange={onChange}
        value={value}
      />
      {title}
    </label>
  );
}
export default ORadio;
