import React from "react";
import PropTypes from "prop-types";
import { Button } from "@oriola-origo/origo-ui-core";
import { Box, Snackbar } from "@material-ui/core";
// eslint-disable-next-line import/no-cycle
import { Text } from "../..";
import { OriolaColors } from "../../../theme";
import {
  AlertErrorCircle,
  AlertInfoCircle,
  AlertWarningCircle,
  AlertSuccessCircle,
  Close,
} from "../../../images";

const Variant = Object.freeze({
  Success: "success",
  Error: "error",
  Info: "info",
  Warning: "warning",
});

function Toast({
  open,
  onClose,
  autoHideDuration,
  variant,
  text,
  icon,
  ...rest
}) {
  const getVariantColor = (value = Variant.Success) => {
    switch (value) {
      case Variant.Warning:
        return OriolaColors.Orange;
      case Variant.Info:
        return OriolaColors.Blue;
      case Variant.Error:
        return OriolaColors.Red;
      case Variant.Success:
      default:
        return OriolaColors.Green;
    }
  };

  const getVariantIcon = (value = Variant.Success) => {
    switch (value) {
      case Variant.Warning:
        return <AlertWarningCircle />;
      case Variant.Info:
        return <AlertInfoCircle />;
      case Variant.Error:
        return <AlertErrorCircle />;
      case Variant.Success:
      default:
        return <AlertSuccessCircle />;
    }
  };

  return (
    <Snackbar open={open} onClose={onClose} autoHideDuration={autoHideDuration}>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        bgcolor={getVariantColor(variant)}
        borderRadius="4px"
        px={2}
        py={1}
        minWidth="300px"
        {...rest}
      >
        {icon || getVariantIcon(variant)}
        <Text mx={1} flexGrow={1} color={OriolaColors.White}>
          {text}
        </Text>
        <Button variant="transparent" onClick={onClose}>
          <Close />
        </Button>
      </Box>
    </Snackbar>
  );
}

Toast.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  autoHideDuration: PropTypes.number,
  variant: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.shape({}),
};

Toast.defaultProps = {
  open: false,
  onClose: () => {},
  autoHideDuration: null,
  icon: null,
  variant: Variant.Success,
  text: "",
};

export default Toast;
