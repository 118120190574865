import { v4 as uuidv4 } from "uuid";
import {
  AttachmentService,
  rest as RestService,
} from "@oriola-origo/origo-common-client-lib";
import { ensureTrailingSlash } from "../../../utils/url/url";

export const ProjectStatus = Object.freeze({
  DRAFT: "DRAFT",
  NEW: "NEW",
  IN_PROGRESS: "IN_PROGRESS",
  FINISHED: "FINISHED",
  ABORTED: "ABORTED",
});

export const AttachmentType = Object.freeze({
  SOURCE: "SOURCE",
  RESULT: "RESULT",
});

export const Projects = Object.freeze({
  FETCH_STARTED: "PROJECTS_FETCH_STARTED",
  FETCH_FINISHED: "PROJECTS_FETCH_FINISHED",
  FETCH_ERROR: "PROJECTS_FETCH_ERROR",
  UPDATE_PROJECTS: "PROJECTS_UPDATE",
  UPDATE_EDIT_PROJECT: "PROJECTS_UPDATE_EDIT_PROJECT",
  UPDATE_UPLOADED_FILES: "PROJECTS_UPDATE_UPLOADED_FILES",
  CREATE_STARTED: "PROJECTS_CREATE_STARTED",
  CREATE_FINISHED: "PROJECTS_CREATE_FINISHED",
  CREATE_ERROR: "PROJECTS_CREATE_ERROR",
  CLEAR_ERRORS: "PROJECTS_CLEAR_ERRORS",
  CLEAR_EDIT_PROJECT: "PROJECTS_CLEAR_PROJECT_EDIT",
  SET_FILTERS: "SET_FILTERS",
});

const DRAFT_PROJECT_ID = "draft_project_id";
const DEFAULT_PAGE_SIZE = 10;
const apiUrl = ensureTrailingSlash(process.env.REACT_APP_DIGIPHARMA_API_URL);

// -- ACTIONS --

const createFetchPath = ({
  page,
  pageSize,
  customerId,
  startDate,
  endDate,
  status,
  search,
  sortBy,
}) => {
  const basePath = `${apiUrl}digipharma/v2/projects`;

  const queryParams = {
    page,
    pageSize,
    customerId,
    startDate,
    endDate,
    status,
    search,
    sortBy,
  };

  const queryParamsStr = Object.keys(queryParams)
    .filter(key => queryParams[key] != null)
    .map(key => `${key}=${queryParams[key]}`)
    .join("&");

  return `${basePath}?${queryParamsStr}`;
};

export const fetchProjects =
  (
    pageSize = DEFAULT_PAGE_SIZE,
    filters = {},
    page = 1,
    appendResults = false
  ) =>
  // eslint-disable-next-line consistent-return
  async (dispatch, getState) => {
    try {
      dispatch({ type: Projects.FETCH_STARTED });

      const { customerId, startDate, endDate, status, search, sortBy } =
        filters;
      const path = createFetchPath({
        page,
        pageSize,
        customerId,
        startDate,
        endDate,
        status,
        search,
        sortBy,
      });
      const result = await RestService.get(path, {
        enableAbortController: true,
      });
      let projects = result.data;
      const { hasMore } = result;
      const { totalCount } = result;

      //  append results?
      if (appendResults === true) {
        projects = getState().projects.projects.concat(projects);
      }

      const projectResults = {
        projects,
        hasMore,
        totalCount,
        lastFetchedPage: page,
      };
      dispatch({
        type: Projects.FETCH_FINISHED,
        payload: projectResults,
      });

      return Promise.resolve(projectResults);
    } catch (error) {
      dispatch({ type: Projects.FETCH_ERROR, payload: error });
    }
  };

export const fetchProject = projectId => async (dispatch, getState) => {
  try {
    dispatch({ type: Projects.FETCH_STARTED });

    // base path
    const path = `${apiUrl}digipharma/v2/projects/${projectId}`;

    const project = await RestService.get(path);

    // find and update
    const projects = getState().projects.projects.slice(0);
    const index = projects.findIndex(
      tempProject => tempProject.id === projectId
    );
    if (index !== -1) {
      projects[index] = project;
    } else {
      projects.push(project);
    }

    dispatch({
      type: Projects.FETCH_FINISHED,
      payload: { projects },
    });

    return Promise.resolve(project);
  } catch (error) {
    dispatch({ type: Projects.FETCH_ERROR, payload: error });
    return Promise.reject(error);
  }
};

export const createProject =
  (project, uploadedAttachmentFiles, userData, selectedOrganization) =>
  async dispatch => {
    try {
      dispatch({ type: Projects.CREATE_STARTED });

      // create path and save project data
      const savePath = `${apiUrl}digipharma/v2/projects`;
      const savedProject = await RestService.post(savePath, project);
      // create attachments
      const { id } = savedProject;
      const attachmentService = new AttachmentService(savePath);
      const result = await attachmentService.createAttachments(
        id,
        uploadedAttachmentFiles
      );

      const projectWithAttachments = {
        ...savedProject,
        attachments: result.map(tempResult => ({
          ...tempResult.attachment,
          uploader: {
            id: userData.userId,
            name: userData.name,
            organization: {
              id: selectedOrganization.id,
              name: selectedOrganization.name,
            },
          },
        })),
        userData,
        selectedOrganization,
      };

      // update with attachments
      const updatePath = `${apiUrl}digipharma/v2/projects/${id}?disableUserNotify=true`;
      await RestService.put(updatePath, projectWithAttachments);

      dispatch({ type: Projects.CREATE_FINISHED });

      return Promise.resolve(projectWithAttachments);
    } catch (error) {
      dispatch({ type: Projects.CREATE_ERROR, payload: error });
      return Promise.reject(error);
    }
  };

export const modifyProject =
  (project, uploadedAttachmentFiles, userData, selectedOrganization) =>
  async dispatch => {
    try {
      dispatch({ type: Projects.CREATE_STARTED });

      // get deleted attachments and remove
      const deletedAttachments = project.attachments.filter(
        attachment => attachment.deleted === true
      );
      const attachmentService = new AttachmentService(
        `${apiUrl}digipharma/v2/projects/`
      );
      if (deletedAttachments.length > 0) {
        await attachmentService.deleteAttachments(
          project.id,
          deletedAttachments
        );
      }

      // get not deleted attachments
      const validAttachments = project.attachments.filter(
        attachment => attachment.deleted !== true
      );

      // create attachments
      const { id } = project;
      const result = await attachmentService.createAttachments(
        id,
        uploadedAttachmentFiles
      );

      const projectWithAttachments = {
        ...project,
        attachments: validAttachments.concat(
          result.map(tempResult => ({
            ...tempResult.attachment,
            uploader: {
              id: userData.userId,
              name: userData.name,
              organization: {
                id: selectedOrganization.id,
                name: selectedOrganization.name,
              },
            },
          }))
        ),
        selectedOrganization,
      };

      // update with attachments
      const updatePath = `${apiUrl}digipharma/v2/projects/${id}`;
      await RestService.put(updatePath, projectWithAttachments);

      dispatch({ type: Projects.CREATE_FINISHED });

      return Promise.resolve(projectWithAttachments);
    } catch (error) {
      dispatch({ type: Projects.CREATE_ERROR, payload: error });
      return Promise.reject(error);
    }
  };

export const createProjectDraft =
  (customerId, customerName, userData, service, url) => async dispatch => {
    const projectDraft = {
      id: DRAFT_PROJECT_ID,
      customerId,
      customerName,
      contactInfo: {
        email: userData.email,
        name: userData.name,
        phoneNumber: userData.phoneNumber,
      },
      service,
      attachments: [],
      title: null,
      description: "",
      status: ProjectStatus.DRAFT,
      url,
      coverLetter: "",
    };

    // update
    dispatch({ type: Projects.UPDATE_EDIT_PROJECT, payload: projectDraft });

    return Promise.resolve(projectDraft);
  };

export const setProjectStatus = status => async (dispatch, getState) => {
  const existingEditProject = getState().projects.editProject;
  if (existingEditProject != null) {
    // copy
    const editProject = { ...existingEditProject };

    // update
    editProject.status = status;

    // update
    dispatch({ type: Projects.UPDATE_EDIT_PROJECT, payload: editProject });
  }
};

export const setProjectTitle = title => async (dispatch, getState) => {
  const existingEditProject = getState().projects.editProject;
  if (existingEditProject != null) {
    // copy
    const editProject = { ...existingEditProject };

    // update
    editProject.title = title;

    // update
    dispatch({ type: Projects.UPDATE_EDIT_PROJECT, payload: editProject });
  }
};

export const setProjectDescription =
  description => async (dispatch, getState) => {
    const existingEditProject = getState().projects.editProject;
    if (existingEditProject != null) {
      // copy
      const editProject = { ...existingEditProject };

      // update
      editProject.description = description;

      // update
      dispatch({ type: Projects.UPDATE_EDIT_PROJECT, payload: editProject });
    }
  };

export const setProjectCoverLetter =
  coverLetter => async (dispatch, getState) => {
    const existingEditProject = getState().projects.editProject;
    if (existingEditProject != null) {
      // copy
      const editProject = { ...existingEditProject };

      // update
      editProject.coverLetter = coverLetter;

      // update
      dispatch({ type: Projects.UPDATE_EDIT_PROJECT, payload: editProject });
    }
  };

export const setProjectService = service => async (dispatch, getState) => {
  const existingEditProject = getState().projects.editProject;
  if (existingEditProject != null) {
    // copy
    const editProject = { ...existingEditProject };

    // update
    editProject.service = service;

    // update
    dispatch({ type: Projects.UPDATE_EDIT_PROJECT, payload: editProject });
  }
};

export const setProjectDeadline =
  deadlineIsoDate => async (dispatch, getState) => {
    const existingEditProject = getState().projects.editProject;
    if (existingEditProject != null) {
      // copy
      const editProject = { ...existingEditProject };

      // update
      editProject.deadlineAt = deadlineIsoDate;

      // update
      dispatch({ type: Projects.UPDATE_EDIT_PROJECT, payload: editProject });
    }
  };

export const setProjectCustomer =
  (customerId, customerName) => async (dispatch, getState) => {
    const existingEditProject = getState().projects.editProject;
    if (existingEditProject != null) {
      // copy
      const editProject = { ...existingEditProject };

      // update
      editProject.customerId = customerId;
      editProject.customerName = `${customerId} - ${customerName}`;

      // update
      dispatch({ type: Projects.UPDATE_EDIT_PROJECT, payload: editProject });
    }
  };

export const setProjectAttachmentToBeRemoved =
  (attachmentId, value) => async (dispatch, getState) => {
    const existingEditProject = getState().projects.editProject;
    if (existingEditProject != null) {
      // copy
      const editProject = { ...existingEditProject };

      // update
      const attachments = editProject.attachments.slice(0);
      const index = attachments.findIndex(a => a.id === attachmentId);
      if (index !== -1) {
        attachments[index].deleted = value;
      }
      editProject.attachments = attachments;

      // update
      dispatch({ type: Projects.UPDATE_EDIT_PROJECT, payload: editProject });
    }
  };

export const setProjectEdit = project => {
  // create deep copy
  const editProject = JSON.parse(JSON.stringify(project));
  return async dispatch => {
    dispatch({ type: Projects.UPDATE_EDIT_PROJECT, payload: editProject });
  };
};

export const clearProjectEdit = () => async dispatch => {
  dispatch({
    type: Projects.CLEAR_EDIT_PROJECT,
  });
};

export const addUploadedAttachmentFile =
  (attachmentType, file, uploader) => async (dispatch, getState) => {
    const uploadedAttachmentFiles =
      getState().projects.uploadedAttachmentFiles.slice(0);

    // add new
    dispatch({
      type: Projects.UPDATE_UPLOADED_FILES,
      payload: uploadedAttachmentFiles.concat({
        id: uuidv4(),
        attachmentType,
        origFileName: file.name,
        file,
        uploader,
      }),
    });
  };

export const removeUploadedAttachmentFile =
  id => async (dispatch, getState) => {
    const uploadedAttachmentFiles =
      getState().projects.uploadedAttachmentFiles.slice(0);

    // remove existing
    const index = uploadedAttachmentFiles.findIndex(file => file.id === id);
    if (index !== -1) {
      uploadedAttachmentFiles.splice(index, 1);

      // update
      dispatch({
        type: Projects.UPDATE_UPLOADED_FILES,
        payload: uploadedAttachmentFiles,
      });
    }
  };

export const clearUploadedAttachmentFiles = () => async dispatch => {
  dispatch({
    type: Projects.UPDATE_UPLOADED_FILES,
    payload: [],
  });
};

export const clearProjectErrors = () => async dispatch => {
  dispatch({
    type: Projects.CLEAR_ERRORS,
  });
};

export const setFilters = filters => dispatch => {
  dispatch({
    type: Projects.SET_FILTERS,
    payload: filters,
  });
};

// -- REDUCER --

const INIT_STATE = {
  projects: [],
  fetchingProjects: false,
  projectFetchError: null,
  hasMore: false,
  totalCount: 0,
  lastFetchedPage: null,
  editProject: null,
  uploadedAttachmentFiles: [],
  creatingProject: false,
  projectCreateError: null,
  filters: {},
};

// eslint-disable-next-line default-param-last
export const projectsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case Projects.FETCH_STARTED:
      return { ...state, fetchingProjects: true, projectFetchError: null };
    case Projects.FETCH_FINISHED: {
      const { projects, lastFetchedPage, hasMore, totalCount } = action.payload;
      return {
        ...state,
        fetchingProjects: false,
        projects,
        hasMore,
        totalCount,
        lastFetchedPage,
      };
    }
    case Projects.FETCH_ERROR:
      return {
        ...state,
        fetchingProjects: false,
        projectFetchError: action.payload,
      };
    case Projects.UPDATE_PROJECTS:
      return { ...state, projects: action.payload };
    case Projects.UPDATE_EDIT_PROJECT:
      return { ...state, editProject: action.payload };
    case Projects.UPDATE_UPLOADED_FILES:
      return { ...state, uploadedAttachmentFiles: action.payload };
    case Projects.CREATE_STARTED:
      return { ...state, creatingProject: true, projectCreateError: null };
    case Projects.CREATE_FINISHED:
      return { ...state, creatingProject: false };
    case Projects.CREATE_ERROR:
      return {
        ...state,
        creatingProject: false,
        projectCreateError: action.payload,
      };
    case Projects.CLEAR_ERRORS:
      return { ...state, projectCreateError: null, projectFetchError: null };
    case Projects.CLEAR_EDIT_PROJECT:
      return {
        ...INIT_STATE,
        filters: state.filters,
      };
    case Projects.SET_FILTERS:
      return { ...state, filters: action.payload };
    default:
      return state;
  }
};
