import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import { OriolaColors } from "../../../theme";

function Text({ children, color, variant, noWrap, ...rest }) {
  return (
    <Box {...rest} color={color || OriolaColors.NavyBlue}>
      <Typography variant={variant} noWrap={noWrap}>
        {children}
      </Typography>
    </Box>
  );
}

Text.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  variant: PropTypes.string,
  noWrap: PropTypes.bool,
};

Text.defaultProps = {
  children: null,
  color: null,
  variant: "body1",
  noWrap: false,
};

export default Text;
