import React from "react";
import PropTypes from "prop-types";
import { Box, makeStyles } from "@material-ui/core";
import { OriolaColors } from "@oriola-origo/origo-ui-core";
// eslint-disable-next-line import/no-cycle
import { Text } from "../..";

const useStyles = makeStyles(() => ({
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "end",
  },
}));

function TitleValue({ title, children, actions, ...rest }) {
  const classes = useStyles();
  return (
    <Box {...rest}>
      <div className={classes.titleContainer}>
        <Text variant="subtitle2" mb={1} color={OriolaColors.DarkGrey}>
          {title}
        </Text>
        {actions}
      </div>
      {children}
    </Box>
  );
}

TitleValue.propTypes = {
  title: PropTypes.string.isRequired,
  actions: PropTypes.shape({}),
  children: PropTypes.shape({}),
};

TitleValue.defaultProps = {
  actions: null,
  children: null,
};

export default TitleValue;
