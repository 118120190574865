import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { userReducer } from "./user/user";
import { projectsReducer } from "./projects/projects";
import { organizationReducer } from "./organization/organization";
import { toastReducer } from "./toast/toast";

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    user: userReducer,
    projects: projectsReducer,
    organization: organizationReducer,
    toast: toastReducer,
  });

export default createRootReducer;
