import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { OriolaColors } from "../../../theme";
// eslint-disable-next-line import/no-cycle
import { Text } from "../..";
import { projectStatusToText } from "../../../utils/project/project";
import { ProjectStatus as Status } from "../../../redux/reducers";

function ProjectStatus({ status, ...rest }) {
  const { t } = useTranslation();
  const bgcolor =
    status !== Status.ABORTED ? OriolaColors.LightGreen : OriolaColors.LightRed;
  const color =
    status !== Status.ABORTED ? OriolaColors.Green : OriolaColors.Red;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      padding="4px 10px"
      bgcolor={bgcolor}
      borderRadius="20px"
      mt={1}
      className="projectStatus"
      {...rest}
    >
      <Text color={color} noWrap>
        {projectStatusToText(t, status)}
      </Text>
    </Box>
  );
}

ProjectStatus.propTypes = {
  status: PropTypes.string,
};

ProjectStatus.defaultProps = {
  status: "",
};

export default ProjectStatus;
