import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Box, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  Dropdown,
  AutoComplete,
  MuiDatePicker,
} from "@oriola-origo/origo-ui-core";
import { OriolaColors } from "../../theme";
// eslint-disable-next-line import/no-cycle
import {
  NaviRibbon,
  Text,
  Input,
  ContentContainer,
  FileList,
  ButtonGroup,
  ComponentToggle,
  UrlQueryParams,
  Toast,
  ConfirmationDialog,
  FileUpload,
} from "..";
import {
  createProject,
  createProjectDraft,
  setProjectTitle,
  setProjectDescription,
  setProjectService,
  clearProjectEdit,
  setProjectDeadline,
  addUploadedAttachmentFile,
  removeUploadedAttachmentFile,
  AttachmentType,
  clearUploadedAttachmentFiles,
  searchOrganizations,
  clearOrganizations,
  setProjectCustomer,
  setProjectEdit,
  getOrganizationById,
  showToast,
  hideToast,
  clearProjectErrors,
} from "../../redux/reducers";
import ContactInfo from "../../contactInformation";
import { isOriolaUser } from "../../utils/auth/permissions";
import {
  validateProjectFields,
  validateProjectDateFields,
} from "../../utils/project/project";
import { AddFile, Attachment } from "../../images";

const DATE_FORMAT = "DD.MM.YYYY";

function CreateProject({ history }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const [initialized, setInitialized] = useState(false);
  const [validateFields, setValidateFields] = useState({});
  const [validateDateFields, setValidateDateFields] = useState({});
  const [showExitConfirmation, setShowExitConfirmation] = useState(false);
  const toast = useSelector(state => state.toast);
  const { editProject, uploadedAttachmentFiles, creatingProject } = useSelector(
    state => state.projects
  );
  const { selectedOrganizationId, userData } = useSelector(state => state.user);
  const { organizations, searchingOrganizations, organizationSearchError } =
    useSelector(state => state.organization);
  const defaultService = t("regulatoryAffairsAndTranslations");

  useEffect(() => {
    if (
      initialized === false &&
      selectedOrganizationId != null &&
      userData != null
    ) {
      // set to be initialized
      setInitialized(true);

      // url template for opening this project
      const { origin } = window.location;
      const url = `${origin}/project/[projectId]`;

      // oriola user will select the organization so no need to fetch
      if (isOriolaUser(userData) === true) {
        dispatch(createProjectDraft(null, null, userData, defaultService, url));
      } else {
        // fetch own organization data
        dispatch(getOrganizationById(selectedOrganizationId))
          .then(organization => {
            if (organization != null) {
              dispatch(
                createProjectDraft(
                  selectedOrganizationId,
                  `${organization.id} - ${organization.name}`,
                  userData,
                  defaultService,
                  url
                )
              );
            }
          })
          .catch(error => {
            const errStatus = error.status != null ? `(${error.status})` : "";
            dispatch(
              showToast(`${t("organizationFetchError")} ${errStatus}`, "error")
            );
          });
      }
    }
  }, [
    dispatch,
    selectedOrganizationId,
    defaultService,
    userData,
    initialized,
    t,
  ]);

  const onRibbonClicked = id => {
    if (id === 0) {
      setShowExitConfirmation(true);
    }
  };

  const onDeadlineChanged = momentDate => {
    let newDate;

    if (momentDate != null) {
      newDate = momentDate.endOf("day").toISOString();
    }

    const validation = validateProjectDateFields(newDate, false);
    setValidateDateFields(validation.fields);

    dispatch(setProjectDeadline(newDate));
  };

  const getDeadlineAt = project => {
    const isoDate = project.deadlineAt;
    if (isoDate != null && moment(isoDate).isValid()) {
      return isoDate;
    }

    return null;
  };

  const onFilesSelected = files => {
    files.forEach(file => {
      dispatch(addUploadedAttachmentFile(AttachmentType.SOURCE, file));
    });
  };

  const onSendOrderClick = project => {
    // validate
    const validation = validateProjectFields(project);
    setValidateFields(validation.fields);

    const dateValidation = validateProjectDateFields(project.deadlineAt, false);
    setValidateDateFields(dateValidation.fields);

    // if all ok
    if (validation.isValid && dateValidation.isValid) {
      const selectedOrganization = userData.organizations?.filter(
        org => org.id === selectedOrganizationId
      )[0];
      // send to backend
      dispatch(
        createProject(
          project,
          uploadedAttachmentFiles,
          userData,
          selectedOrganization
        )
      )
        .then(createdProject => {
          if (createdProject != null) {
            // clear uploaded files
            dispatch(clearUploadedAttachmentFiles());

            // set new project to be the edit project
            dispatch(setProjectEdit(createdProject));

            // and and go to project page
            history.push(`/project/${createdProject.id}`);

            // show great success
            dispatch(showToast(t("projectSaved"), "success", 3000));
          }
        })
        .catch(error => {
          // show error
          const errStatus = error.status != null ? `(${error.status})` : "";
          dispatch(
            showToast(`${t("projectSaveFailed")} ${errStatus}`, "error")
          );
        });
    } else {
      // show error
      dispatch(showToast(t("fillRequiredFields"), "error", 3000));
    }
  };

  const onExitClick = () => {
    dispatch(clearProjectEdit());
    history.push("/");
  };

  const onOrganizationSelected = organization => {
    if (organization != null) {
      dispatch(setProjectCustomer(organization.id, organization.name));
    }
  };

  const onSearchOrganizations = query => {
    if (query != null && query.length >= 3) {
      dispatch(searchOrganizations(query));
    } else {
      dispatch(clearOrganizations());
    }
  };

  const getAutoCompleteErrorAndText = () => {
    if (organizationSearchError != null) {
      return {
        error: true,
        errorText: t("organizationFetchError"),
      };
    }
    if (validateFields.customerIdError === true) {
      return {
        error: true,
        errorText: t("requiredField"),
      };
    }
    return {
      error: false,
    };
  };

  const getDatepickerErrorAndText = () => {
    if (
      validateDateFields.deadlinePastDateError &&
      validateDateFields.deadlineHasValue
    ) {
      return {
        error: true,
        errorText: t("requiredFieldDeadlineWishRequirement"),
      };
    }
    if (validateDateFields.deadlineNotValidError) {
      return {
        error: true,
        errorText: t("requiredField"),
      };
    }
    return {
      error: false,
      errorText: "",
    };
  };

  const onHideToast = () => {
    dispatch(clearProjectErrors());
    dispatch(hideToast());
  };

  const getLabel = option => `${option.id} - ${option.name}`;

  const autoCompleteErrorAndText = getAutoCompleteErrorAndText();
  const datepickerErrorAndText = getDatepickerErrorAndText();

  const safeProject = editProject || {};
  return (
    <Box mx={[0, 0, 4, 8, 8]} my={4}>
      <ConfirmationDialog
        open={showExitConfirmation}
        text={`${t("discardChanges")}?`}
        onOk={() => onExitClick()}
        onCancel={() => setShowExitConfirmation(false)}
      />
      <Toast
        open={toast.open}
        text={toast.text}
        variant={toast.variant}
        autoHideDuration={toast.autoHideDuration}
        onClose={() => onHideToast()}
      />
      <UrlQueryParams />
      <NaviRibbon
        items={[
          { id: 0, value: t("projects") },
          { id: 1, value: t("newProject") },
        ]}
        onClick={id => onRibbonClicked(id)}
      />
      <Text mt={2} variant="h1">
        {t("newProject")}
      </Text>
      <Box
        mt={2}
        display="flex"
        flexDirection={mobile === false ? "row" : "column"}
      >
        <ContentContainer
          flexGrow={1}
          p={[1, 1, 1, 4, 4]}
          id="createProjectView"
        >
          <Box width={mobile === false ? "75%" : "100%"}>
            <Text>{t("projectTitle")}</Text>
            <Input
              disabled={creatingProject === true}
              value={safeProject.title || ""}
              mt={1}
              placeholder={t("writeProjectTitle")}
              onChanged={value => dispatch(setProjectTitle(value))}
              error={validateFields.titleError}
              errorText={t("requiredField")}
              id="title"
            />
            <Text mt={4}>{t("organization")}</Text>
            <ComponentToggle
              showPrimary={isOriolaUser(userData) === false}
              primaryComponent={
                <Text mt={1}>{`${safeProject.customerName}`}</Text>
              }
              secondaryComponent={
                <AutoComplete
                  mt={1}
                  options={organizations}
                  loadingOptions={searchingOrganizations}
                  onOptionSelected={(event, value) =>
                    onOrganizationSelected(value)
                  }
                  getOptionLabel={option => getLabel(option)}
                  onSearch={query => onSearchOrganizations(query)}
                  onInputChange={event => {
                    const value = String(event.target.value || "");
                    const searchValue = value.toLowerCase();
                    onSearchOrganizations(searchValue);
                  }}
                  getOptionSelected={(option, value) =>
                    option.name === value.name
                  }
                  placeholder={t("writeOrganizationNameOrId")}
                  noOptionsText={t("noOrganizations")}
                  loadingText={t("loading")}
                  error={autoCompleteErrorAndText.error}
                  errorText={autoCompleteErrorAndText.errorText}
                  id="organizationAutoCompleteBox"
                >
                  {autoCompleteErrorAndText.error === true &&
                    autoCompleteErrorAndText.errorText != null && (
                      <Text
                        mt={1}
                        className="errorText"
                        variant="body1"
                        color={OriolaColors.Red}
                      >
                        {autoCompleteErrorAndText.errorText}
                      </Text>
                    )}
                </AutoComplete>
              }
            />
            <Text mt={4}>{t("service")}</Text>
            <Dropdown
              mt={1}
              disabled
              value={safeProject.service || defaultService}
              emptySelectionLabel={t("select")}
              options={[defaultService]}
              onSelectOption={value => dispatch(setProjectService(value))}
              showSelectNone={false}
              error={validateFields.serviceError}
              errorText={t("requiredField")}
              inputProps={{ id: "serviceDropDown" }}
            />
            <Text mt={4}>{t("description")}</Text>
            <Input
              disabled={creatingProject === true}
              value={safeProject.description || ""}
              mt={1}
              placeholder={t("writeDescription")}
              rows={5}
              multiline
              onChanged={value => dispatch(setProjectDescription(value))}
              id="description"
            />
            <Text mt={4}>{t("deadlineWish")}</Text>
            <Box width={mobile === false ? "40%" : null} mt={1}>
              <MuiDatePicker
                disabled={creatingProject === true}
                placeholder={t("setDeadline")}
                value={getDeadlineAt(safeProject)}
                disablePast
                format={DATE_FORMAT}
                onChange={value => onDeadlineChanged(value)}
                locale={i18n.language}
                error={datepickerErrorAndText.error}
                helperText={datepickerErrorAndText.errorText}
                id="deadlineWishDatePicker"
              />
            </Box>
            <Text mt={4}>{t("sourceFilesOptional")}</Text>
            <FileUpload
              id="sourceFileUpload"
              mt={1}
              onFilesSelected={files => onFilesSelected(files)}
              addFileImg={<AddFile />}
              title={t("uploadFile")}
              description={`${t("or").toLowerCase()} ${t(
                "dragAndDrop"
              ).toLowerCase()}`}
            />
            <FileList
              id="sourceFileList"
              mt={1}
              files={uploadedAttachmentFiles}
              getFileOpts={attachment => ({
                name: attachment.origFileName,
                rightButtonText: t("remove"),
              })}
              onRightButtonClick={(e, attachment) =>
                dispatch(removeUploadedAttachmentFile(attachment.id))
              }
              attachmentImg={<Attachment />}
              emptyListText={t("noAttachments")}
            />
            <ButtonGroup
              mt={4}
              disabled={creatingProject === true}
              showProgress={creatingProject === true}
              leftText={t("sendOrder")}
              leftId="send-order-button"
              onLeftClick={() => onSendOrderClick(safeProject)}
              rightText={t("cancel")}
              rightId="cancel-order-button"
              onRightClick={() => setShowExitConfirmation(true)}
            />
          </Box>
        </ContentContainer>
        <Box
          width={mobile === false ? "20%" : "100%"}
          my={mobile === true ? 2 : 0}
          mx={mobile === true ? 0 : 2}
        >
          <ContentContainer flexShrink={1} p={[1, 1, 1, 2, 4]}>
            <Text variant="h4">{t("contactInformation")}</Text>
            <Text variant="body2" mt={3}>
              {ContactInfo.name}
            </Text>
            <Text mt={1}>{ContactInfo.title}</Text>
            <Text mt={1}>{`${t("telAbbreviation")} ${ContactInfo.phone}`}</Text>
            <Text noWrap mt={1}>
              {ContactInfo.email}
            </Text>
          </ContentContainer>
        </Box>
      </Box>
    </Box>
  );
}

CreateProject.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  match: PropTypes.shape({}),
};

CreateProject.defaultProps = {
  history: null,
  match: null,
};

export default withRouter(CreateProject);
