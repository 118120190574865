import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";

function NotFound({ title }) {
  const { t } = useTranslation();

  const titleText = title || `404 ${t("pageNotFound").toUpperCase()}`;

  return (
    <Box
      id="notFoundError"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      p={8}
    >
      <h5>{titleText}</h5>
    </Box>
  );
}

NotFound.propTypes = {
  title: PropTypes.string,
};

NotFound.defaultProps = {
  title: null,
};
export default NotFound;
