/* eslint-disable no-console */
import { rest as RestService } from "@oriola-origo/origo-common-client-lib";
import { ensureTrailingSlash } from "../../../utils/url/url";
import Delay from "../../../utils/delay/delay";

const delay = new Delay();

export const Organization = Object.freeze({
  SEARCH_STARTED: "ORGANIZATION_SEARCH_STARTED",
  SEARCH_FINISHED: "ORGANIZATION_SEARCH_FINISHED",
  SEARCH_ERROR: "ORGANIZATION_SEARCH_ERROR",
  FETCH_STARTED: "ORGANIZATION_FETCH_STARTED",
  FETCH_FINISHED: "ORGANIZATION_FETCH_FINISHED",
  FETCH_ERROR: "ORGANIZATION_FETCH_ERROR",
});

const apiUrl = ensureTrailingSlash(process.env.REACT_APP_DIGIPHARMA_API_URL);

// -- ACTIONS --

const doSearch = async (dispatch, query) => {
  try {
    dispatch({ type: Organization.SEARCH_STARTED });

    const path = `${apiUrl}organization/v1/search?query=${query}`;
    const organizations = await RestService.get(path);
    dispatch({ type: Organization.SEARCH_FINISHED, payload: organizations });

    return Promise.resolve(organizations);
  } catch (error) {
    console.error("searchOrganizations", error);
    dispatch({ type: Organization.SEARCH_ERROR, payload: error });
    return Promise.reject(error);
  }
};

export const searchOrganizations = query => async dispatch => {
  delay.run(() => doSearch(dispatch, query), 1000);
};

export const getOrganizationById = id => async dispatch => {
  try {
    dispatch({ type: Organization.FETCH_STARTED });

    const path = `${apiUrl}organization/v1/id/${id}`;
    const organization = await RestService.get(path);

    dispatch({ type: Organization.FETCH_FINISHED });

    return Promise.resolve(organization);
  } catch (error) {
    console.error("getOrganizationById", error);
    dispatch({ type: Organization.FETCH_ERROR, payload: error });
    return Promise.reject(error);
  }
};

export const clearOrganizations = () => dispatch => {
  // cancel any requests
  delay.clear();

  // clear results
  dispatch({
    type: Organization.SEARCH_FINISHED,
    payload: [],
  });
};

// -- REDUCER --

const INIT_STATE = {
  organizations: [],
  searchingOrganizations: false,
  organizationSearchError: null,
  fetchingOrganization: false,
  organizationFetchError: null,
};
// eslint-disable-next-line default-param-last
export const organizationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case Organization.SEARCH_STARTED:
      return {
        ...state,
        searchingOrganizations: true,
        organizationSearchError: null,
      };
    case Organization.SEARCH_FINISHED:
      return {
        ...state,
        searchingOrganizations: false,
        organizations: action.payload,
      };
    case Organization.SEARCH_ERROR:
      return {
        ...state,
        searchingOrganizations: false,
        organizationSearchError: action.payload,
      };
    case Organization.FETCH_STARTED:
      return {
        ...state,
        fetchingOrganization: true,
        organizationFetchError: null,
      };
    case Organization.FETCH_FINISHED:
      return {
        ...state,
        fetchingOrganization: false,
        organizations: action.payload,
      };
    case Organization.FETCH_ERROR:
      return {
        ...state,
        fetchingOrganization: false,
        organizationFetchError: action.payload,
      };
    default:
      return state;
  }
};
