import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { TableCell, TableRow } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { OriolaColors } from "../../../../theme";
// eslint-disable-next-line import/no-cycle
import { ProjectStatus } from "../../..";
import { isOriolaUser } from "../../../../utils/auth/permissions";
import formatDeadlineDate from "../../../../utils/project/formatDeadlineDate";

const useStyles = makeStyles(() => ({
  root: {
    "&:hover": {
      backgroundColor: OriolaColors.LightGrey,
    },
    cursor: "pointer",
    borderBottom: `1px solid ${OriolaColors.LightGrey}`,
  },
  TableCell: {
    fontSize: "14px",
  },
  TableCellNonBreaking: {
    fontSize: "14px",
    whiteSpace: "nowrap",
  },
}));

function Row({ project }) {
  const classes = useStyles();
  const { userData } = useSelector(state => state.user);
  const oriolaUser = isOriolaUser(userData);
  const history = useHistory();

  const renderCustomerDetail = () => oriolaUser && project.customerName;
  const onRowClick = projectId => history.push(`/project/${projectId}`);

  return (
    <TableRow
      hover
      key={project.id}
      onClick={() => onRowClick(project.id)}
      className={classes.root}
    >
      <TableCell className={classes.TableCellNonBreaking}>
        {project.projectId || project.id.split("-")[0]}
      </TableCell>
      <TableCell className={classes.TableCell}>{project.title}</TableCell>
      <TableCell className={classes.TableCell}>
        {renderCustomerDetail()}
      </TableCell>
      <TableCell className={classes.TableCell}>
        {formatDeadlineDate(project.startDate) || "-"}
      </TableCell>
      <TableCell className={classes.TableCell}>
        {formatDeadlineDate(project.deadlineAt)}
      </TableCell>
      <TableCell className={classes.TableCell}>
        {formatDeadlineDate(project.endDate) || "-"}
      </TableCell>
      <TableCell className={classes.TableCell}>
        <ProjectStatus display="inline-block" status={project.status} />
      </TableCell>
    </TableRow>
  );
}

Row.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.string,
    projectId: PropTypes.string,
    title: PropTypes.string,
    customerName: PropTypes.string,
    startDate: PropTypes.string,
    deadlineAt: PropTypes.string,
    endDate: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
};

export default Row;
