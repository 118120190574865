import React from "react";
import PropTypes from "prop-types";
import { Box, TextField } from "@material-ui/core";
import { OriolaColors } from "../../../theme";
// eslint-disable-next-line import/no-cycle
import { Text } from "../..";

function Input({
  color,
  value,
  placeholder,
  onChanged,
  maxLength,
  rows,
  multiline,
  error,
  errorText,
  disabled,
  ...rest
}) {
  return (
    <Box {...rest} color={color || OriolaColors.NavyBlue}>
      <TextField
        fullWidth
        multiline={multiline}
        rows={rows || 1}
        value={value}
        variant="outlined"
        placeholder={placeholder}
        margin="dense"
        onChange={event => onChanged(event.target.value)}
        inputProps={{
          maxLength,
        }}
        style={{ margin: 0 }}
        disabled={disabled}
        error={error}
      />
      {error === true && errorText != null && (
        <Text
          className="errorText"
          variant="body1"
          color={OriolaColors.Red}
          mt={1}
        >
          {errorText}
        </Text>
      )}
    </Box>
  );
}

Input.propTypes = {
  color: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChanged: PropTypes.func,
  maxLength: PropTypes.number,
  rows: PropTypes.number,
  multiline: PropTypes.bool,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  disabled: PropTypes.bool,
};

Input.defaultProps = {
  color: null,
  value: "",
  placeholder: "",
  onChanged: () => {
    /* noop */
  },
  maxLength: null,
  rows: null,
  multiline: false,
  error: false,
  errorText: null,
  disabled: false,
};

export default Input;
