// import React from 'react';
import PropTypes from "prop-types";

function ComponentToggle({
  showPrimary,
  primaryComponent,
  secondaryComponent,
}) {
  if (showPrimary === true) {
    return primaryComponent;
  }
  return secondaryComponent;
}

ComponentToggle.propTypes = {
  showPrimary: PropTypes.bool.isRequired,
  primaryComponent: PropTypes.node.isRequired,
  secondaryComponent: PropTypes.node.isRequired,
};

ComponentToggle.defaultProps = {};

export default ComponentToggle;
