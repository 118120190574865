import React from "react";
import PropTypes from "prop-types";
import { Dialog, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line import/no-cycle
import { Text, ButtonGroup } from "../..";

function ConfirmationDialog({ text, open, onOk, onCancel }) {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onCancel}>
      <Box
        p={4}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Text variant="h4">{text}</Text>
        <ButtonGroup
          mt={4}
          leftText={t("ok")}
          onLeftClick={onOk}
          rightText={t("cancel")}
          onRightClick={onCancel}
          rightId="cancel-dialog-button"
          leftId="ok-dialog-button"
        />
      </Box>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  text: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ConfirmationDialog;
