import { TableCell, TableRow } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import styles from "./fake_row.module.css";

function FakeSpan({ width }) {
  return <span className={styles["fake-span"]} style={{ width }} />;
}
function FakeRow() {
  return (
    <TableRow hover>
      <TableCell>
        <FakeSpan width="50px" />
      </TableCell>
      <TableCell>
        <FakeSpan width="140px" />
      </TableCell>
      <TableCell>
        <FakeSpan width="280px" />
      </TableCell>
      <TableCell>
        <FakeSpan width="85px" />
      </TableCell>
      <TableCell>
        <FakeSpan width="85px" />
      </TableCell>
      <TableCell>
        <FakeSpan width="85px" />
      </TableCell>
      <TableCell>
        <FakeSpan width="50px" />
      </TableCell>
    </TableRow>
  );
}

export default FakeRow;

FakeSpan.propTypes = {
  width: PropTypes.string.isRequired,
};
