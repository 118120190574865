export const Roles = Object.freeze({
  ORIOLA_EDIT: "digipharma_oriola_edit",
  CUSTOMER_EDIT: "digipharma_customer_edit",
});

export const Permissions = Object.freeze({
  VIEW_PROJECTS: "VIEW_PROJECTS",
  VIEW_ALL_PROJECTS: "VIEW_ALL_PROJECTS",
  VIEW_CUSTOMER_PROJECTS: "VIEW_CUSTOMER_PROJECTS",
  VIEW_PROJECT_DETAILS: "VIEW_PROJECT_DETAILS",
  CREATE_PROJECT: "CREATE_PROJECT",
});

const ActionToRoles = Object.freeze({
  VIEW_PROJECTS: [Roles.ORIOLA_EDIT, Roles.CUSTOMER_EDIT],
  VIEW_ALL_PROJECTS: [Roles.ORIOLA_EDIT],
  VIEW_CUSTOMER_PROJECTS: [Roles.ORIOLA_EDIT, Roles.CUSTOMER_EDIT],
  VIEW_PROJECT_DETAILS: [Roles.ORIOLA_EDIT, Roles.CUSTOMER_EDIT],
  CREATE_PROJECT: [Roles.ORIOLA_EDIT, Roles.CUSTOMER_EDIT],
});

export const isOriolaUser = user => {
  if (user == null) {
    return false;
  }

  const { roles } = user;
  return roles.includes(Roles.ORIOLA_EDIT);
};

export const isAllowedByRoles = (user, action) => {
  if (user == null || action == null) {
    return false;
  }

  const { roles } = user;
  const actionRoles = ActionToRoles[action] || [];
  for (let i = 0; i < roles.length; i += 1) {
    if (actionRoles.includes(roles[i]) === true) {
      return true;
    }
  }

  return false;
};

export const isAllowedByOrganization = (user, organizationId) => {
  if (user == null || organizationId == null) {
    return false;
  }

  const { organizationIds } = user;
  return organizationIds.includes(organizationId);
};
