import React from "react";
import PropTypes from "prop-types";
import { Route, useLocation, matchPath } from "react-router-dom";
// eslint-disable-next-line import/no-cycle
import { NotFound } from "..";

const isDevEnv = process.env.REACT_APP_STAGE === "dev";

function ShowOnlyInDevelopment({ children, isRoute, path }) {
  const location = useLocation();
  const isCorrectPath = matchPath(location.pathname, { path }) !== null;

  if (isRoute) {
    return isDevEnv && isCorrectPath ? (
      children
    ) : (
      <Route path={location.pathname} component={NotFound} />
    );
  }

  return isDevEnv ? children : null;
}

ShowOnlyInDevelopment.propTypes = {
  isRoute: PropTypes.bool,
  path: PropTypes.string,
  children: PropTypes.node.isRequired,
};
ShowOnlyInDevelopment.defaultProps = {
  isRoute: false,
  path: "",
};

export default ShowOnlyInDevelopment;
