import moment from "moment";
import { ProjectStatus as Status } from "../../redux/reducers";

export const validateProjectFields = project => {
  const titleError = project.title == null || project.title.length === 0;
  const serviceError = project.service == null || project.service.length === 0;
  const customerIdError =
    project.customerId == null || project.customerId.length === 0;

  return {
    isValid:
      titleError === false &&
      serviceError === false &&
      customerIdError === false,
    fields: {
      titleError,
      serviceError,
      customerIdError,
    },
  };
};

export const validateProjectDate = (isoDate, allowPastDates) => {
  const hasDate = isoDate != null;

  const isValid =
    isoDate != null &&
    moment(isoDate).isValid() &&
    (allowPastDates ? true : moment(isoDate).isAfter());

  const futureDateOrPastDateAllowed = allowPastDates
    ? true
    : moment(isoDate).isAfter();

  return {
    deadlineHasValue: hasDate,
    deadlineNotValidError: !isValid,
    deadlinePastDateError: !futureDateOrPastDateAllowed,
  };
};

export const validateProjectDateFields = (isoDate, allowPastDates) => {
  const { deadlineHasValue, deadlinePastDateError, deadlineNotValidError } =
    validateProjectDate(isoDate, allowPastDates);

  return {
    isValid:
      deadlineHasValue === true &&
      deadlinePastDateError === false &&
      deadlineNotValidError === false,
    fields: {
      deadlineHasValue,
      deadlinePastDateError,
      deadlineNotValidError,
    },
  };
};

export const projectStatusToText = (t, status) => {
  switch (status) {
    case Status.NEW:
      return t("new");
    case Status.IN_PROGRESS:
      return t("inProgress");
    case Status.FINISHED:
      return t("finished");
    case Status.ABORTED:
      return t("aborted");
    default:
      return t("unknown");
  }
};
