import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import { isAllowedByRoles } from "../../utils/auth/permissions";

function ProtectedRoute({
  path,
  component,
  failComponent,
  exact,
  userData,
  permission,
}) {
  const canSeePath = isAllowedByRoles(userData, permission);

  if (canSeePath === true) {
    return <Route exact={exact} path={path} component={component} />;
  }

  return <Route exact={exact} path={path} component={failComponent} />;
}

ProtectedRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
  failComponent: PropTypes.elementType.isRequired,
  userData: PropTypes.shape({}).isRequired,
  permission: PropTypes.string,
  exact: PropTypes.bool,
};

ProtectedRoute.defaultProps = {
  permission: "",
  exact: false,
};

export default ProtectedRoute;
