import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Box } from "@material-ui/core";
import PropTypes from "prop-types";
import { Button, MuiDatePicker } from "@oriola-origo/origo-ui-core";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash/debounce";
import moment from "moment";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import { fetchProjects, showToast, hideToast } from "../../redux/reducers";
// eslint-disable-next-line import/no-cycle
import {
  ProjectsTable,
  ContentContainer,
  Text,
  UrlQueryParams,
  Toast,
} from "..";
import OrganizationFilter from "./organizationFilter";
import {
  Permissions,
  isAllowedByRoles,
  isAllowedByOrganization,
  isOriolaUser,
} from "../../utils/auth/permissions";
import { OriolaColors } from "../../theme";

import Search from "./search";
import StatusSelector from "./statusSelector";
import {
  getObjectFromUrlParams,
  getUrlParamsFromObject,
} from "../../utils/url/url";
import { setFilters } from "../../redux/reducers/projects/projects";
import { useScroll } from "../../hooks";

const PROJECTS_ON_PAGE = 50;
const SEARCH_DEBOUNCE_TIME_MS = 500;

export const isFiltersEmpty = filters => {
  let filterIsEmpty = true;
  // eslint-disable-next-line no-restricted-syntax
  for (const filter in filters) {
    if (filter !== "organizationId" && filters[filter]) {
      filterIsEmpty = false;
      break;
    }
  }
  return filterIsEmpty;
};
function Projects({ history }) {
  const { t, i18n } = useTranslation();
  const [hideShowMoreBtn, setHideShowMoreBtn] = useState(false);
  const { windowHeight, documentHeight, scrollTop } = useScroll();
  const dispatch = useDispatch();
  const { userData, selectedOrganizationId } = useSelector(state => state.user);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const {
    projects,
    fetchingProjects,
    projectFetchError,
    lastFetchedPage,
    hasMore,
    filters = {},
    totalCount,
  } = useSelector(state => state.projects);
  const [initialized, setInitialized] = useState(false);
  const toast = useSelector(state => state.toast);
  const [startDateValid, setStartDateValid] = useState(true);

  const updateFilters = updatedFilters => {
    dispatch(setFilters(updatedFilters));
    const path = window.location.origin + window.location.pathname;
    const urlParams = getUrlParamsFromObject({
      organizationId: selectedOrganizationId,
      ...updatedFilters,
    });
    dispatch(setFilters(updatedFilters));
    window.history.replaceState(null, null, `${path}?${urlParams}`);
  };
  // initial fetcher
  useEffect(() => {
    if (
      userData != null &&
      selectedOrganizationId != null &&
      initialized === false
    ) {
      // check rights
      const canViewAll = isAllowedByRoles(
        userData,
        Permissions.VIEW_ALL_PROJECTS
      );
      const canViewCustomerSpecific = isAllowedByRoles(
        userData,
        Permissions.VIEW_CUSTOMER_PROJECTS
      );
      const organizationAllowed = isAllowedByOrganization(
        userData,
        selectedOrganizationId
      );

      const filtersFromUrlParams = getObjectFromUrlParams(
        (window.location.search || "").replace("?", "")
      );
      let newFilters;
      // and fetch by them
      if (canViewAll === true) {
        newFilters = { ...filtersFromUrlParams, ...filters };
      } else if (
        canViewCustomerSpecific === true &&
        organizationAllowed === true
      ) {
        newFilters = {
          ...filtersFromUrlParams,
          ...filters,
          customerId: selectedOrganizationId,
        };
      }
      updateFilters(newFilters);
      dispatch(fetchProjects(PROJECTS_ON_PAGE, newFilters));
      setInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, selectedOrganizationId, dispatch, initialized, setInitialized]);

  useEffect(() => {
    if (projectFetchError != null) {
      const status =
        projectFetchError.status != null ? `(${projectFetchError.status})` : "";
      if (!projectFetchError?.status === 520) {
        dispatch(showToast(`${t("projectFetchError")} ${status}`, "error"));
      }
    }
  }, [projectFetchError, dispatch, t]);

  const loadMoreProjects = useCallback(
    (newFilters, appendResults, wantedPage) => {
      const canViewAll = isAllowedByRoles(
        userData,
        Permissions.VIEW_ALL_PROJECTS
      );
      const canViewCustomerSpecific = isAllowedByRoles(
        userData,
        Permissions.VIEW_CUSTOMER_PROJECTS
      );
      const organizationAllowed = isAllowedByOrganization(
        userData,
        selectedOrganizationId
      );
      if (canViewAll === true) {
        // no need for organization
        dispatch(
          fetchProjects(PROJECTS_ON_PAGE, newFilters, wantedPage, appendResults)
        );
      } else if (
        canViewCustomerSpecific === true &&
        organizationAllowed === true
      ) {
        // no need for year
        dispatch(
          fetchProjects(
            PROJECTS_ON_PAGE,
            {
              ...newFilters,
              customerId: selectedOrganizationId,
            },
            wantedPage,
            appendResults
          )
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, userData]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedLoadMoreProjects = useCallback(
    debounce(loadMoreProjects, SEARCH_DEBOUNCE_TIME_MS),
    [loadMoreProjects]
  );

  const onOrganizationSelected = ({
    id: organizationId,
    name: organizationName,
  }) => {
    const newFilters = {
      ...filters,
      customerId: organizationId,
      customerName: organizationName,
    };
    updateFilters(newFilters);
    loadMoreProjects(newFilters, false);
  };

  const dateRangeValid = (startDate, endDate) => {
    let valid = true;
    if (startDate != null && endDate != null) {
      valid = moment(startDate).isSameOrBefore(endDate);
      setStartDateValid(valid);
    }
    return valid;
  };

  const setStartDate = startDate => {
    const startDateIsValid = !startDate || startDate.isValid();
    if (startDateIsValid) {
      const formattedStartDate = startDate
        ? startDate.format("YYYY-MM-DD")
        : startDate;
      const newFilters = {
        ...filters,
        startDate: formattedStartDate,
      };

      updateFilters(newFilters);
      const rangeValid = dateRangeValid(formattedStartDate, filters.endDate);
      if (rangeValid) {
        loadMoreProjects(newFilters, false);
      }
    }
  };

  const setEndDate = endDate => {
    const endDateIsValid = !endDate || endDate.isValid();
    if (endDateIsValid) {
      const formattedEndDate = endDate ? endDate.format("YYYY-MM-DD") : endDate;
      const newFilters = { ...filters, endDate: formattedEndDate };
      updateFilters(newFilters);

      const rangeValid = dateRangeValid(filters.startDate, formattedEndDate);
      if (rangeValid) {
        loadMoreProjects(newFilters, false);
      }
    }
  };

  const setStatus = status => {
    const newFilters = { ...filters, status };
    updateFilters(newFilters);
    loadMoreProjects(newFilters, false);
  };

  const setSearchValue = value => {
    const newFilters = { ...filters, search: value };
    updateFilters(newFilters);

    debouncedLoadMoreProjects(newFilters, false);
  };

  const loadMore = () => {
    setHideShowMoreBtn(true);
    if (hasMore) loadMoreProjects({ ...filters }, true, lastFetchedPage + 1);
  };
  useEffect(() => {
    if (
      windowHeight + scrollTop + 800 > documentHeight &&
      hideShowMoreBtn &&
      !fetchingProjects
    ) {
      loadMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollTop]);
  useEffect(() => {
    if (orderBy) {
      const newFilters = { ...filters, sortBy: `${orderBy}-${order}` };
      updateFilters(newFilters);
      loadMoreProjects(newFilters, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, order]);

  const clearFilters = () => {
    const newFilters = {
      ...filters,
      search: null,
      startDate: null,
      endDate: null,
      status: null,
      customerName: null,
      customerId: null,
      sortBy: null,
    };
    setOrder("asc");
    setOrderBy("");
    updateFilters(newFilters);
    loadMoreProjects(newFilters, false);
  };
  const onHandleSort = property => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  return (
    <ContentContainer m={[0, 0, 4, 8, 8]} id="projectsView">
      <Toast
        open={toast.open}
        text={toast.text}
        variant={toast.variant}
        autoHideDuration={toast.autoHideDuration}
        onClose={() => dispatch(hideToast())}
      />
      <UrlQueryParams />
      <Box
        display="flex"
        justifyContent="space-between"
        p={3}
        boxShadow="0px 3px 4px -3px rgb(0 0 0/15%)"
        mb={1}
      >
        <Text variant="h2">{t("projects")}</Text>
        {selectedOrganizationId != null && (
          <Button
            onClick={() => history.push("/create")}
            id="create-new-project-button"
          >
            <Text color={OriolaColors.White} noWrap>
              {t("newProject")}
            </Text>
          </Button>
        )}
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        bgcolor={OriolaColors.White}
        position="sticky"
        top={0}
        py={2}
        justifyContent="space-between"
      >
        <Box
          display="flex"
          alignItems="center"
          gridGap="20px 30px"
          padding="0 24px"
          flexWrap="wrap"
        >
          <StatusSelector
            status={filters.status}
            onStatusSelected={setStatus}
          />

          <Box
            display="flex"
            alignItems="center"
            gridGap="0 5px"
            maxWidth="25rem"
          >
            <MuiDatePicker
              value={filters.startDate || null}
              label={t("projectStartDate")}
              onChange={value => setStartDate(value)}
              locale={i18n.language}
              id="startDateFilterDatePicker"
              maxDate={filters.endDate != null ? filters.endDate : undefined}
              error={startDateValid === false}
              keyboardIcon={
                <CalendarTodayOutlinedIcon style={{ color: "#A0A9B6" }} />
              }
            />
            <Text>-</Text>
            <MuiDatePicker
              value={filters.endDate || null}
              label={t("projectEndDate")}
              onChange={value => setEndDate(value)}
              locale={i18n.language}
              id="endDateFilterDatePicker"
              minDate={
                filters.startDate != null ? filters.startDate : undefined
              }
              keyboardIcon={
                <CalendarTodayOutlinedIcon style={{ color: "#A0A9B6" }} />
              }
            />
          </Box>
          {isOriolaUser(userData) && (
            <OrganizationFilter
              width="20rem"
              selectedOrganizationId={filters.customerId}
              selectedOrganizationName={filters.customerName}
              onOrganizationSelected={onOrganizationSelected}
            />
          )}
          <Box>
            <Search
              placeholder={t("search")}
              value={filters.search || ""}
              onChange={val => setSearchValue(val)}
            />
          </Box>
        </Box>
      </Box>
      <Box
        margin="0px 15px"
        display="flex"
        alignItems="center"
        gridGap="0 10px"
      >
        <Text variant="subtitle1" color={OriolaColors.NavyBlue}>
          {`${totalCount} ${t("projects").toLocaleLowerCase()}`}
        </Text>
        <Button
          onClick={clearFilters}
          style={{
            color: isFiltersEmpty(filters)
              ? OriolaColors.DarkGrey
              : OriolaColors.white,
            background: isFiltersEmpty(filters)
              ? OriolaColors.LightGrey
              : OriolaColors.Orange,
            fontWeight: "bold",
            borderRadius: "15px",
            fontSize: "12px",
          }}
        >
          {t("clearFilters")}
        </Button>
      </Box>
      <ProjectsTable
        projects={projects}
        fetchingProjects={fetchingProjects}
        onHandleSort={onHandleSort}
        orderBy={orderBy}
        order={order}
      />
      {hasMore && !hideShowMoreBtn && (
        <Box display="flex" justifyContent="center" paddingY={3}>
          <Button onClick={loadMore} color="primary">
            {t("showMore")}
          </Button>
        </Box>
      )}
    </ContentContainer>
  );
}

Projects.propTypes = {
  history: PropTypes.string,
};

Projects.defaultProps = {
  history: null,
};

export default withRouter(Projects);
