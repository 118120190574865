import { rest as RestService } from "@oriola-origo/origo-common-client-lib";
import { ensureTrailingSlash } from "../url/url";

const apiUrl = ensureTrailingSlash(process.env.REACT_APP_DIGIPHARMA_API_URL);

const getSignedReadUrl = async (projectId, attachmentId) => {
  const path = `${apiUrl}digipharma/v2/projects/${projectId}/attachments/${attachmentId}`;
  const result = await RestService.get(path);
  return result.url;
};

export default getSignedReadUrl;
