import React from "react";
import PropTypes from "prop-types";

import closeImg from "./close/close.svg";
import alertErrorCircleImg from "./alert-error-circle/alert-error-circle.svg";
import alertInfoCircleImg from "./alert-info-circle/alert-info-circle.svg";
import alertSuccessCircleImg from "./alert-ok-circle/alert-ok-circle.svg";
import alertWarningCircleImg from "./alert-warning-circle/alert-warning-circle.svg";
import addCircleImg from "./addCircle/addCircle.svg";
import attachmentImg from "./attachment/attachment.svg";
import addFileImg from "./addFile/addFile.svg";

export function Close({ style }) {
  return <img style={style} alt="cloes" src={closeImg} />;
}

Close.propTypes = {
  style: PropTypes.shape({}),
};
Close.defaultProps = {
  style: {},
};

export function AlertErrorCircle({ style }) {
  return (
    <img style={style} alt="alert-error-circle" src={alertErrorCircleImg} />
  );
}

AlertErrorCircle.propTypes = {
  style: PropTypes.shape({}),
};
AlertErrorCircle.defaultProps = {
  style: {},
};

export function AlertInfoCircle({ style }) {
  return <img style={style} alt="alert-info-circle" src={alertInfoCircleImg} />;
}

AlertInfoCircle.propTypes = {
  style: PropTypes.shape({}),
};
AlertInfoCircle.defaultProps = {
  style: {},
};

export function AlertSuccessCircle({ style }) {
  return (
    <img style={style} alt="alert-success-circle" src={alertSuccessCircleImg} />
  );
}

AlertSuccessCircle.propTypes = {
  style: PropTypes.shape({}),
};
AlertSuccessCircle.defaultProps = {
  style: {},
};

export function AlertWarningCircle({ style }) {
  return (
    <img style={style} alt="alert-warning-circle" src={alertWarningCircleImg} />
  );
}

AlertWarningCircle.propTypes = {
  style: PropTypes.shape({}),
};
AlertWarningCircle.defaultProps = {
  style: {},
};

export function AddCircle({ style }) {
  return <img style={style} alt="add-circle" src={addCircleImg} />;
}

AddCircle.propTypes = {
  style: PropTypes.shape({}),
};
AddCircle.defaultProps = {
  style: {},
};

export function Attachment({ style }) {
  return <img style={style} alt="attachment" src={attachmentImg} />;
}

Attachment.propTypes = {
  style: PropTypes.shape({}),
};
Attachment.defaultProps = {
  style: {},
};

export function AddFile({ style }) {
  return <img style={style} alt="add file" src={addFileImg} />;
}

AddFile.propTypes = {
  style: PropTypes.shape({}),
};
AddFile.defaultProps = {
  style: {},
};
