import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@oriola-origo/origo-ui-core";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { openPortal } from "../../utils/auth/auth";
// eslint-disable-next-line import/no-cycle
import { Text } from "..";
import { OriolaColors } from "../../theme";

function LoginError({ error }) {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      width="100%"
    >
      <Text color={OriolaColors.Error} mt={3}>
        {t("signInFailed")}
      </Text>
      <Box my={1}>{`${t("error")}: ${error.message}`}</Box>
      <Button onClick={() => openPortal(window.location)}>
        {t("openOrigo")}
      </Button>
    </Box>
  );
}

LoginError.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
};

LoginError.defaultProps = {
  error: {},
};

export default LoginError;
