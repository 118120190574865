import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { AutoComplete, OriolaColors } from "@oriola-origo/origo-ui-core";
import VerifiedUserOutlinedIcon from "@material-ui/icons/VerifiedUserOutlined";
import {
  searchOrganizations,
  clearOrganizations,
  getOrganizationById,
} from "../../redux/reducers";

const useStyles = makeStyles(theme => ({
  optionRoot: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  optionIcon: {
    marginLeft: theme.spacing(0.5),
  },
}));

function OrganizationFilter({
  onOrganizationSelected,
  selectedOrganizationId,
  selectedOrganizationName,
  ...containerProps
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { organizations, searchingOrganizations } = useSelector(
    state => state.organization
  );

  const onSearchOrganizations = query => {
    if (query != null && query.length >= 3) {
      dispatch(searchOrganizations(query));
    } else {
      dispatch(clearOrganizations());
    }
  };
  const handleOrganizationSelected = value => {
    onOrganizationSelected(value || { id: null, name: null });
  };

  const getLabel = option => `${option.id} - ${option.name}`;

  const renderOption = option => {
    let content = `${option.id} - ${option.name}`;
    if (option.hasDigipharmaUsers) {
      content = (
        <>
          {`${option.id} - ${option.name}`}
          <VerifiedUserOutlinedIcon
            htmlColor={OriolaColors.secondaryNavyBlue}
            className={classes.optionIcon}
            fontSize="small"
          />
        </>
      );
    }

    return (
      <div className={classes.optionRoot} key={`c-${option.id}`}>
        {content}
      </div>
    );
  };

  useEffect(() => {
    if (selectedOrganizationId && !selectedOrganizationName) {
      dispatch(getOrganizationById(selectedOrganizationId)).then(
        organizationResult => {
          if (organizationResult != null) {
            onOrganizationSelected({
              id: organizationResult.id,
              name: organizationResult.name,
            });
          }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganizationId, selectedOrganizationName]);

  return (
    <Box {...containerProps} margin="0px">
      <AutoComplete
        options={organizations}
        loadingOptions={searchingOrganizations}
        onOptionSelected={(event, value) => handleOrganizationSelected(value)}
        onSearch={query => onSearchOrganizations(query)}
        onInputChange={event => {
          if (event) {
            const value = String(event.target.value || "");
            const searchValue = value.toLowerCase();
            searchValue && onSearchOrganizations(searchValue);
          }
        }}
        value={
          selectedOrganizationId
            ? {
                id: selectedOrganizationId,
                name: selectedOrganizationName || "",
              }
            : null
        }
        getOptionSelected={(option, value) => option.name === value.name}
        getOptionLabel={getLabel}
        renderOption={renderOption}
        placeholder={t("writeOrganizationNameOrId")}
        noOptionsText={t("noOrganizations")}
        loadingText={t("loading")}
        id="organization-filter-autocomplete-box"
      />
    </Box>
  );
}

OrganizationFilter.propTypes = {
  onOrganizationSelected: PropTypes.func,
  selectedOrganizationId: PropTypes.string,
  selectedOrganizationName: PropTypes.string,
};

OrganizationFilter.defaultProps = {
  onOrganizationSelected: () => {
    /* noop */
  },
  selectedOrganizationId: null,
  selectedOrganizationName: null,
};

export default OrganizationFilter;
