import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(8),
  },
  value: {
    marginTop: theme.spacing(1),
  },
  title: {
    marginTop: theme.spacing(2),
    fontWeight: "bold",
  },
  marginLeft: {
    marginTop: theme.spacing(1),
  },
}));

function UserInfo() {
  const classes = useStyles();
  const { userData, tokenData, sessionId } = useSelector(state => state.user);

  const formatDateTime = date => {
    if (date != null) {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    }
    return "-";
  };

  return (
    <div className={classes.root}>
      <h3>{userData.name}</h3>
      <p className={classes.value}>{userData.userId}</p>
      <p className={classes.value}>{userData.email}</p>
      <p className={classes.value}>{`Language: ${userData.language}`}</p>
      <p className={classes.value}>{`Session id: ${sessionId}`}</p>
      <p className={classes.value}>{`Token expire time: ${formatDateTime(
        tokenData.expiryTime
      )}`}</p>
      <h4 className={classes.title}>Roles</h4>
      {userData.roles.map(role => (
        <p key={role} className={classes.value}>
          {role}
        </p>
      ))}
      <h4 className={classes.title}>Organizations</h4>
      {userData.organizationIds.map(org => {
        const text =
          userData.defaultOrganizationId === org ? `${org} (selected)` : org;
        return (
          <p key={org} className={classes.value}>
            {text}
          </p>
        );
      })}
      <h4 className={classes.title}>Profit centers</h4>
      {userData.profitCenters.map(pc => (
        <p key={pc} className={classes.value}>
          {pc}
        </p>
      ))}
    </div>
  );
}

UserInfo.propTypes = {};

export default UserInfo;
