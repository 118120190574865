import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedOrganizationId, showToast } from "../../../redux/reducers";
import { setFilters } from "../../../redux/reducers/projects/projects";
import {
  getObjectFromUrlParams,
  getUrlParamsFromObject,
} from "../../../utils/url/url";

const resolveDefaultOrganizationFromUserData = (userData = {}) => {
  const { organizationIds, defaultOrganizationId } = userData;

  if (defaultOrganizationId) {
    return defaultOrganizationId;
  }

  return organizationIds && organizationIds.length > 0
    ? organizationIds[0]
    : null;
};

function UrlQueryParams() {
  const { selectedOrganizationId, userData } = useSelector(state => state.user);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // run only once
  useEffect(() => {
    const path = window.location.origin + window.location.pathname;

    // get organizationId from the URL
    const filtersFromUrlParams = getObjectFromUrlParams(
      (window.location.search || "").replace("?", "")
    );
    let organizationId =
      filtersFromUrlParams.organizationId || selectedOrganizationId;
    const { startDate, endDate, caseStatus, customerId } = filtersFromUrlParams;

    if (!organizationId) {
      organizationId = resolveDefaultOrganizationFromUserData(userData);
      if (!organizationId) {
        // no org
        dispatch(showToast(t("organizationNotSelected"), "error"));
      }
    }

    organizationId && dispatch(setSelectedOrganizationId(organizationId));
    setFilters({
      customerId,
      startDate,
      endDate,
      caseStatus,
    });

    const urlParams = getUrlParamsFromObject({
      organizationId,
      customerId,
      startDate,
      endDate,
      caseStatus,
    });
    window.history.replaceState(null, null, `${path}?${urlParams}`);
  }, []); // eslint-disable-line

  return null;
}

UrlQueryParams.propTypes = {};

UrlQueryParams.defaultProps = {};

export default UrlQueryParams;
