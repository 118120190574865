import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { OriolaColors } from "../../../theme";

function ContentContainer({ children, ...rest }) {
  return (
    <Box
      bgcolor={OriolaColors.White}
      borderBottom={`1px solid ${OriolaColors.LightGrey}`}
      borderRadius="8px"
      boxShadow={1}
      {...rest}
    >
      {children}
    </Box>
  );
}

ContentContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContentContainer;
