import React from "react";
import FormControl from "@material-ui/core/FormControl";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import OutlinedInput from "@material-ui/core/OutlinedInput";

export type SearchProps = {
  className?: string;
  placeholder: string;
  value: string;
  onChange: (val: string) => void;
};

function Search({
  onChange,
  placeholder,
  value,
  className,
}: Readonly<SearchProps>) {
  return (
    <FormControl
      className={className}
      variant="outlined"
      style={{ height: "40px", width: "250px" }}
    >
      <OutlinedInput
        placeholder={placeholder}
        value={value}
        onChange={event => onChange(event.target.value)}
        margin="dense"
        endAdornment={
          value ? (
            <ClearIcon
              style={{ color: "#A0A9B6" }}
              cursor="pointer"
              onClick={() => onChange("")}
            />
          ) : (
            <SearchIcon style={{ color: "#A0A9B6" }} />
          )
        }
      />
    </FormControl>
  );
}

export default Search;
