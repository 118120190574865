import { useEffect, useState } from "react";

interface ScrollPosition {
  windowHeight: number;
  documentHeight: number;
  scrollTop: number;
}

const useScroll = (): ScrollPosition => {
  const [scrollPosition, setScrollPosition] = useState<ScrollPosition>({
    windowHeight: window.innerHeight,
    documentHeight: document.documentElement.scrollHeight,
    scrollTop: window.scrollY,
  });

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition({
        windowHeight: window.innerHeight,
        documentHeight: document.documentElement.scrollHeight,
        scrollTop: window.scrollY,
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return scrollPosition;
};

export default useScroll;
