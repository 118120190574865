import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { projectStatusToText } from "../../utils/project/project";
import { ProjectStatus } from "../../redux/reducers";
import ORadio from "../inputs/ORadio";

function StatusSelector({ status, onStatusSelected }) {
  const options = [
    ProjectStatus.NEW,
    ProjectStatus.IN_PROGRESS,
    ProjectStatus.FINISHED,
    ProjectStatus.ABORTED,
  ];
  const { t } = useTranslation();

  const renderStatusSelectors = () =>
    ["ALL", ...options].map(option => (
      <ORadio
        key={option}
        type="group"
        title={option === "ALL" ? t("all") : projectStatusToText(t, option)}
        checked={status ? status === option : option === "ALL"}
        onChange={() => onStatusSelected(option === "ALL" ? null : option)}
      />
    ));

  return (
    <Box display="flex" id="status-selector">
      {renderStatusSelectors()}
    </Box>
  );
}

StatusSelector.propTypes = {
  status: PropTypes.string,
  onStatusSelected: PropTypes.func,
};

StatusSelector.defaultProps = {
  status: null,
  onStatusSelected: () => {
    /* noop */
  },
};

export default StatusSelector;
