import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { useTranslation } from "react-i18next";
import { TableSortLabel } from "@material-ui/core";
import { OriolaColors } from "@oriola-origo/origo-ui-core";
// eslint-disable-next-line import/no-cycle
import Row from "./row/row";

import FakeRow from "./fake_row";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  sortLabel: {
    "&:hover": {
      color: OriolaColors.DarkGrey,
    },
    "&.MuiTableSortLabel-active": {
      color: OriolaColors.DarkGrey,
      fontWeight: "bold",
      "&& .MuiTableSortLabel-icon": {
        color: OriolaColors.DarkGrey,
      },
    },
  },
});
function ProjectsTable({
  projects,
  fetchingProjects,
  onHandleSort,
  orderBy,
  order,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const renderLoadingRows = () => {
    const fakeArray = new Array(20).fill(0);
    return fakeArray.map(_ => <FakeRow key={_} />);
  };
  const renderSortLabel = (orderKey, label) => (
    <TableSortLabel
      className={classes.sortLabel}
      active={orderBy === orderKey}
      direction={orderBy === orderKey ? order : "asc"}
      onClick={() => onHandleSort(orderKey)}
    >
      {t(label)}
    </TableSortLabel>
  );
  return (
    <TableContainer component={Paper} style={{ "box-shadow": "none" }}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t("projectId")}</TableCell>
            <TableCell>{renderSortLabel("title", "projectTitle")}</TableCell>
            <TableCell>{t("customer")}</TableCell>
            <TableCell>
              {renderSortLabel("start-date", "projectStartDate")}
            </TableCell>
            <TableCell>
              {renderSortLabel("deadline", "projectDeadline")}
            </TableCell>
            <TableCell>
              {renderSortLabel("end-date", "projectEndDate")}
            </TableCell>
            <TableCell>{t("status")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {projects.map(project => (
            <Row key={project.id} project={project} />
          ))}
          {fetchingProjects && renderLoadingRows()}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

ProjectsTable.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      projectId: PropTypes.string,
      title: PropTypes.string,
      customerName: PropTypes.string,
      startDate: PropTypes.string,
      deadlineAt: PropTypes.string,
      endDate: PropTypes.string,
      status: PropTypes.string,
    })
  ).isRequired,
  fetchingProjects: PropTypes.bool.isRequired,
  onHandleSort: PropTypes.func.isRequired,
  orderBy: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
};

ProjectsTable.defaultProps = {};

export default ProjectsTable;
