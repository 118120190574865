/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Avatar, Box, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import {
  Button,
  Dropdown,
  Progress,
  AutoComplete,
  MuiDatePicker,
} from "@oriola-origo/origo-ui-core";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  fetchProject,
  setProjectEdit,
  modifyProject,
  setProjectStatus,
  setProjectTitle,
  setProjectDescription,
  setProjectCoverLetter,
  setProjectService,
  clearProjectEdit,
  setProjectDeadline,
  addUploadedAttachmentFile,
  removeUploadedAttachmentFile,
  AttachmentType,
  ProjectStatus as Status,
  clearUploadedAttachmentFiles,
  searchOrganizations,
  clearOrganizations,
  setProjectCustomer,
  setProjectAttachmentToBeRemoved,
  showToast,
  hideToast,
  getOrganizationById,
} from "../../redux/reducers";
// eslint-disable-next-line import/no-cycle
import {
  FileList,
  ContentContainer,
  TitleValue,
  Text,
  ButtonGroup,
  ProjectStatus,
  Input,
  ComponentToggle,
  FileUpload,
  UrlQueryParams,
  Toast,
  ConfirmationDialog,
} from "..";
import getSignedReadUrl from "../../utils/s3/s3";
import { OriolaColors } from "../../theme";
import { isOriolaUser } from "../../utils/auth/permissions";
import {
  validateProjectFields,
  validateProjectDateFields,
  projectStatusToText,
} from "../../utils/project/project";
import formatDeadlineDate from "../../utils/project/formatDeadlineDate";
import { AddFile, Attachment } from "../../images";
import oriolaContactInformation from "../../contactInformation";

const DATE_FORMAT = "DD.MM.YYYY";

const Fields = Object.freeze({
  Title: "title",
  Description: "description",
  SourceFiles: "sourceFiles",
  CoverLetter: "coverLetter",
  ResultFiles: "resultFiles",
  Status: "status",
  Service: "service",
  Deadline: "deadline",
});

const useStyles = makeStyles(theme => ({
  fileList: {
    border: 0,
    marginTop: "10px",
    "& > div": {
      marginBottom: "10px",
      border: `1px solid ${OriolaColors.Border}`,
      borderRadius: "4px",
      "&:last-child": {
        marginBottom: 0,
      },
      "& > div:nth-child(2)": {
        display: "none",
      },
    },
  },
  back_btn_box: {
    display: "flex",
    gridColumnGap: "10px",
    alignItems: "center",
    cursor: "pointer",
  },
  back_btn: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: OriolaColors.NavyBlue,
  },
}));

export const formatDate = (date = null) => {
  if (moment(date).isValid()) {
    return formatDeadlineDate(typeof date === "string" ? date : date.toJSON());
  }
  return "-";
};
export const renderStartEndDate = (
  date,
  modifierName,
  modifierCompany,
  isEnddateAndAbortedText
) => (
  <Text variant="body1">
    {isEnddateAndAbortedText} {formatDate(date)}
    {date && (
      <>
        <br />
        {modifierName}
        <br />
        {modifierCompany}
      </>
    )}
  </Text>
);

function Project({ match, history }) {
  const classes = useStyles();

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [validateFields, setValidateFields] = useState({
    titleError: false,
    customerIdError: false,
    serviceError: false,
  });
  const [validateDateFields, setValidateDateFields] = useState({
    deadlineNotValidError: false,
    deadlinePastDateError: false,
    deadlineHasValue: false,
  });
  const [formattedDescription, setFormattedDescription] = useState([]);
  const { fetchingProjects } = useSelector((state: any) => state.projects);
  const {
    editProject,
    uploadedAttachmentFiles,
    creatingProject,
    projectFetchError,
  } = useSelector((state: any) => state.projects);
  const { userData, selectedOrganizationId } = useSelector(
    (state: any) => state.user
  );
  const { organizations, searchingOrganizations, organizationSearchError } =
    useSelector((state: any) => state.organization);
  const toast = useSelector((state: any) => state.toast);
  const [oldCustomerName, setOldCustomerName] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [addResultsMode, setAddResultsMode] = useState(false);
  const safeProject = editProject || {};
  const oriolaUser = isOriolaUser(userData);
  const contactInfo = oriolaUser
    ? safeProject.contactInfo || {}
    : oriolaContactInformation;
  const [exitConfirmation, setExitConfirmation] = useState({
    show: false,
    goBack: false,
  });

  const { projectId } = match.params;
  const defaultService = t("regulatoryAffairsAndTranslations");
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    dispatch(clearProjectEdit());
  }, [dispatch]);

  useEffect(() => {
    if (editProject == null && projectId != null) {
      dispatch(fetchProject(projectId))
        .then(resultProject => {
          if (resultProject != null) {
            // set project to be the edit project directly even if edit mode is not on yet
            dispatch(setProjectEdit(resultProject));
          }
        })
        .catch(error => {
          // show error
          const errStatus = error.status != null ? `(${error.status})` : "";
          dispatch(
            showToast(`${t("projectFetchError")} ${errStatus}`, "error")
          );
        });
    }
  }, [projectId, editProject, dispatch, t]);

  const downloadAttachment = async (attachmentId, filename) => {
    const url = await getSignedReadUrl(projectId, attachmentId);
    const result = await fetch(url);
    const file = new Blob([await result.blob()], { type: result.type });
    const blobUrl = window.URL.createObjectURL(file);

    const link = document.createElement("a");
    link.href = blobUrl;
    link.target = "_blank";
    link.download = filename;

    // Currently Firefox previews the downloaded pdfs - no matter of what.
    // To prevent this we give false hints to browser that the downloaded file might
    // not really be a pdf or something else that it can preview. This doesnt have
    // any effect on the file itself.
    link.type = "text/plain";

    link.click();
  };

  const attachments = safeProject.attachments || [];
  const sourceAttachments = attachments.filter(
    attachment => attachment.attachmentType === AttachmentType.SOURCE
  );
  const onDownloadAllSourceAttachmentsClick = async event => {
    event.preventDefault();
    sourceAttachments.forEach(attachment => {
      downloadAttachment(attachment.id, attachment.origFileName);
    });
  };
  const resultAttachments = attachments.filter(
    attachment => attachment.attachmentType === AttachmentType.RESULT
  );
  const onDownloadAllResultAttachmentsClick = async event => {
    event.preventDefault();
    resultAttachments.forEach(attachment => {
      downloadAttachment(attachment.id, attachment.origFileName);
    });
  };

  const onDownloadClick = async (event, attachmentId, filename) => {
    event.preventDefault();
    await downloadAttachment(attachmentId, filename);
  };

  const onBackToProjectsClicked = () => {
    if (editMode || addResultsMode) {
      setExitConfirmation({ show: true, goBack: true });
    } else {
      history.push("/");
    }
  };

  const onEdit = type => {
    // store old customer id
    setOldCustomerName(safeProject.customerName);

    // start edit
    type === "editProject" ? setEditMode(true) : setAddResultsMode(true);
  };

  const doModifyProject = (project, newAttachmentFiles) => {
    const selectedOrganization = userData.organizations?.filter(
      org => org.id === selectedOrganizationId
    )[0];
    // send to backend
    dispatch(
      modifyProject(
        { ...project, userData },
        newAttachmentFiles,
        userData,
        selectedOrganization
      )
    )
      .then(createdProject => {
        if (createdProject != null) {
          // clear
          dispatch(clearUploadedAttachmentFiles());
          dispatch(clearProjectEdit());
          setEditMode(false);
          setAddResultsMode(false);

          // show great success
          dispatch(showToast(t("projectSaved"), "success", 3000));
        }
      })
      .catch(error => {
        // show error
        const errStatus = error.status != null ? `(${error.status})` : "";
        dispatch(showToast(`${t("projectSaveFailed")} ${errStatus}`, "error"));
      });
  };

  const onAbort = (project, value) => {
    const status = value === true ? Status.ABORTED : Status.NEW;
    const updatedProject = { ...project, status };
    doModifyProject(updatedProject, []);
  };

  const isEditable = field => {
    if (!(editMode || addResultsMode)) {
      return false;
    }

    // for oriola user all fields can be modified
    if (oriolaUser === true) {
      const fieldIsCoverLetterOrResultFiles = [
        Fields.ResultFiles,
        Fields.CoverLetter,
      ].includes(field);
      if (
        (addResultsMode && fieldIsCoverLetterOrResultFiles) ||
        (editMode && !fieldIsCoverLetterOrResultFiles)
      ) {
        return true;
      }
      return false;
    }

    // customer, check per field
    return (
      field === Fields.Title ||
      field === Fields.Description ||
      field === Fields.SourceFiles ||
      field === Fields.Deadline
    );
  };

  const handleAttachmentClick = (edit, e, attachment) => {
    if (edit === true) {
      // this is uploaded file
      if (attachment.file != null) {
        dispatch(removeUploadedAttachmentFile(attachment.id));
      } else {
        const deleted = attachment.deleted || false;
        dispatch(setProjectAttachmentToBeRemoved(attachment.id, !deleted));
      }
    } else {
      onDownloadClick(e, attachment.id, attachment.origFileName).catch(() => {
        // show error
        dispatch(showToast(t("attachmentFetchError"), "error", 3000));
      });
    }
  };

  const getAttachmentFileOpts = (edit, attachment) => {
    const name = attachment.origFileName;

    // normal mode
    if (edit === false) {
      return {
        name,
        rightButtonText: t("download").toUpperCase(),
        userName: `${attachment.uploader?.name} / ${attachment.uploader?.organization.name}`,
        uploadedAt: attachment.uploadedAt,
      };
    }

    // edit mode
    let highlightColor = null;
    let rightButtonText = null;
    // normal?
    if (attachment.file != null) {
      highlightColor = OriolaColors.LightGreen;
      rightButtonText = t("remove");
    } else if (attachment.deleted === true) {
      highlightColor = OriolaColors.LightRed;
      rightButtonText = t("revert");
    } else {
      rightButtonText = t("remove");
      highlightColor = "";
    }

    return {
      name,
      rightButtonText,
      highlightColor,
    };
  };

  const onOrganizationSelected = organization => {
    if (organization != null) {
      dispatch(setProjectCustomer(organization.id, organization.name));
    }
  };

  const onSearchOrganizations = query => {
    if (query != null && query.length >= 3) {
      dispatch(searchOrganizations(query));
    } else {
      dispatch(clearOrganizations());
    }
  };

  const onFilesSelected = (attachmentType, files) => {
    const organizationId = +userData.organizationIds[0];
    dispatch(getOrganizationById(organizationId))
      .then(organization => {
        if (organization != null) {
          const uploader = {
            name: userData.name,
            id: userData.userId,
            company: {
              name: organization.name,
              id: organizationId,
            },
          };
          files.forEach(file => {
            dispatch(addUploadedAttachmentFile(attachmentType, file, uploader));
          });
        }
      })
      .catch(error => {
        const errStatus = error.status != null ? `(${error.status})` : "";
        dispatch(
          showToast(`${t("organizationFetchError")} ${errStatus}`, "error")
        );
      });
  };

  const onSaveOrderClick = project => {
    // validate
    const validation = validateProjectFields(project);
    setValidateFields(validation.fields);

    const dateValidation = validateProjectDateFields(project.deadlineAt, true);
    setValidateDateFields(dateValidation.fields);

    // if all ok
    if (validation.isValid && dateValidation.isValid) {
      doModifyProject(project, uploadedAttachmentFiles);
    } else {
      // show error
      dispatch(showToast(t("fillRequiredFields"), "error", 3000));
    }
  };

  const onCancelEditClick = goBack => {
    setEditMode(false);
    setAddResultsMode(false);
    dispatch(clearProjectEdit());
    if (goBack === true) {
      history.push("/");
    } else {
      setExitConfirmation({ show: false, goBack: false });
    }
  };

  const getAutoCompleteErrorAndText = () => {
    if (organizationSearchError != null) {
      return {
        error: true,
        errorText: t("organizationFetchError"),
      };
    }
    if (validateFields.customerIdError === true) {
      return {
        error: true,
        errorText: t("requiredField"),
      };
    }
    return {
      error: false,
    };
  };

  const getDatepickerErrorAndText = () => {
    if (
      validateDateFields.deadlinePastDateError &&
      validateDateFields.deadlineHasValue
    ) {
      return {
        error: true,
        errorText: t("requiredFieldDeadlineWishRequirement"),
      };
    }
    if (validateDateFields.deadlineNotValidError) {
      return {
        error: true,
        errorText: t("requiredField"),
      };
    }
    return {
      error: false,
      errorText: "",
    };
  };

  const onDeadlineChanged = momentDate => {
    let newDate;

    if (momentDate != null) {
      newDate = momentDate.endOf("day").toISOString();
    }

    const validation = validateProjectDateFields(newDate, false);
    setValidateDateFields(validation.fields);

    dispatch(setProjectDeadline(newDate));
  };

  const getDeadlineAt = project => {
    const isoDate = project.deadlineAt;
    if (isoDate != null && moment(isoDate).isValid()) {
      return isoDate;
    }

    return null;
  };

  const renderTopButton = (type: "editProject" | "addResults") => {
    // if fetching
    if (fetchingProjects === true) {
      return <Progress px={2} show size={30} />;
    }

    // if errors or finished
    if (projectFetchError != null) {
      return null;
    }

    // if finished and normal customer
    if (oriolaUser === false && safeProject.status === Status.FINISHED) {
      return null;
    }

    // if not editing
    if (!(editMode || addResultsMode)) {
      return (
        <Button
          onClick={() => onEdit(type)}
          id={
            type === "editProject"
              ? "edit-project-button"
              : "add-results-project-button"
          }
        >
          {t(type)}
        </Button>
      );
    }

    return null;
  };

  const renderAbortButton = project => {
    if (oriolaUser === true && creatingProject === false) {
      const aborted = project.status === Status.ABORTED;
      const textColor =
        aborted === true ? OriolaColors.NavyBlue : OriolaColors.Red;
      const text = aborted === true ? t("continueProject") : t("abortProject");

      return (
        <Button
          variant="transparent"
          onClick={() => onAbort(project, !aborted)}
        >
          <Text color={textColor}>{text}</Text>
        </Button>
      );
    }
    return null;
  };

  const datepickerErrorAndText = getDatepickerErrorAndText();

  const renderDeadlineStatusCreatedAt = () => (
    <Box
      ml={mobile === false ? 4 : 0}
      mt={mobile === false ? 0 : 4}
      borderLeft={mobile ? 0 : `1px solid ${OriolaColors.LightGrey}`}
      pl={mobile === false ? 4 : 0}
    >
      <TitleValue title={t("createDate")}>
        <Text variant="body1">{formatDate(safeProject.createdAt)}</Text>
      </TitleValue>
      <TitleValue title={t("deadline")} mt={4}>
        <ComponentToggle
          showPrimary={isEditable(Fields.Deadline) === false}
          primaryComponent={
            <Text variant="body1">
              {formatDeadlineDate(safeProject.deadlineAt)}
            </Text>
          }
          secondaryComponent={
            <MuiDatePicker
              disabled={creatingProject === true}
              dateFormat={DATE_FORMAT}
              value={getDeadlineAt(safeProject)}
              placeholder={t("setDeadline")}
              onChange={value => onDeadlineChanged(value)}
              error={datepickerErrorAndText.error}
              helperText={datepickerErrorAndText.errorText}
              locale={i18n.language}
              disablePast
              id="deadlineWishDatePicker"
            />
          }
        />
      </TitleValue>
      <TitleValue title={t("status")} mt={4}>
        <ComponentToggle
          showPrimary={isEditable(Fields.Status) === false}
          primaryComponent={
            <ProjectStatus display="inline-block" status={safeProject.status} />
          }
          secondaryComponent={
            safeProject.status === "ABORTED" ? (
              <ProjectStatus
                display="inline-block"
                status={safeProject.status}
              />
            ) : (
              <Dropdown
                value={safeProject.status}
                emptySelectionLabel={t("select")}
                onFormatOptionLabel={value => projectStatusToText(t, value)}
                options={Object.keys(Status).filter(
                  key => key !== Status.DRAFT && key !== Status.ABORTED
                )}
                onSelectOption={value => dispatch(setProjectStatus(value))}
                inputProps={{ id: "statusDropdown" }}
              />
            )
          }
        />
      </TitleValue>
      <TitleValue title={t("projectStartDate")} mt={4}>
        {renderStartEndDate(
          safeProject.startDate,
          safeProject.startDetails?.modifier?.name,
          safeProject.startDetails?.modifier?.company.name,
          false
        )}
      </TitleValue>
      <TitleValue title={t("projectEndDate")} mt={4}>
        {renderStartEndDate(
          safeProject.endDate,
          safeProject.endDetails?.modifier?.name,
          safeProject.endDetails?.modifier?.company.name,
          safeProject.status === Status.ABORTED ? t("aborted") : false
        )}
      </TitleValue>
      <TitleValue title={t("contactInformation")} mt={4}>
        <Text variant="body1">{contactInfo.name}</Text>
        <Text variant="body1">{contactInfo.email}</Text>
        <Text variant="body1">{contactInfo.phoneNumber}</Text>
      </TitleValue>
    </Box>
  );

  const renderFormattedDescription = () => {
    if (formattedDescription.length === 0) {
      return <Text variant="body1">-</Text>;
    }
    return (
      <>
        {formattedDescription.map(desc => (
          <Text variant="body1" key={desc} minHeight={20}>
            {desc}
          </Text>
        ))}
      </>
    );
  };

  const getLabel = option => `${option.id} - ${option.name}`;

  const autoCompleteErrorAndText = getAutoCompleteErrorAndText();

  const newSourceAttachments = uploadedAttachmentFiles.filter(
    attachment => attachment.attachmentType === AttachmentType.SOURCE
  );

  const newResultAttachments = uploadedAttachmentFiles.filter(
    attachment => attachment.attachmentType === AttachmentType.RESULT
  );

  useEffect(() => {
    if (safeProject.description) {
      setFormattedDescription(safeProject.description.split("\n"));
    }
  }, [safeProject.description]);

  const rendeSaveCancelAbortButtons = type => {
    if (
      (type === "editProject" && editMode) ||
      (type === "addResults" && addResultsMode)
    ) {
      return (
        <Box display="flex" my={4} mx={[1, 1, 1, 4, 4]}>
          <ButtonGroup
            flexGrow={1}
            disabled={
              creatingProject === true || safeProject.status === Status.ABORTED
            }
            showProgress={creatingProject === true}
            leftText={t("save")}
            rightText={t("cancel")}
            onLeftClick={() => onSaveOrderClick(safeProject)}
            onRightClick={() =>
              setExitConfirmation({ show: true, goBack: false })
            }
            leftId="save-order-button"
            rightId="cancel-order-button"
          />
          <Box ml={1}>{renderAbortButton(safeProject)}</Box>
        </Box>
      );
    }
    return null;
  };

  return (
    <Box mx={[0, 0, 4, 8, 8]} my={2} id="projectView">
      <ConfirmationDialog
        open={exitConfirmation.show}
        text={`${t("discardChanges")}?`}
        onOk={() => onCancelEditClick(exitConfirmation.goBack)}
        onCancel={() => setExitConfirmation({ show: false, goBack: false })}
      />
      <Toast
        open={toast.open}
        text={toast.text}
        variant={toast.variant}
        autoHideDuration={toast.autoHideDuration}
        onClose={() => dispatch(hideToast())}
      />
      <UrlQueryParams />
      <Box className={classes.back_btn_box} onClick={onBackToProjectsClicked}>
        <Avatar className={classes.back_btn}>
          <KeyboardArrowLeftIcon fontSize="medium" />
        </Avatar>
        <Text>{t("backToProjects")}</Text>
      </Box>

      <ContentContainer mt={2}>
        <Box
          height="70px"
          display="flex"
          flexDirection="row"
          alignItems="center"
          borderBottom={`2px solid ${OriolaColors.LightGrey}`}
          paddingX={3}
        >
          <Box flexGrow={1}>
            <Text variant="h2">
              {t("project")}{" "}
              {safeProject.projectId || safeProject.id?.split("-")[0] || "-"}
            </Text>
          </Box>
          <Box>{renderTopButton("editProject")}</Box>
        </Box>
        <Box>
          <Box display="flex" m={[1, 1, 1, 4, 4]}>
            <Box width={mobile === false ? "80%" : "100%"}>
              <TitleValue title={t("projectTitle")}>
                <ComponentToggle
                  showPrimary={isEditable(Fields.Title) === false}
                  primaryComponent={
                    <Text variant="body1">{safeProject.title}</Text>
                  }
                  secondaryComponent={
                    <Input
                      mt={1}
                      disabled={creatingProject === true}
                      value={safeProject.title || ""}
                      placeholder={t("writeProjectTitle")}
                      onChanged={value => dispatch(setProjectTitle(value))}
                      error={validateFields.titleError}
                      errorText={t("requiredField")}
                      id="title"
                    />
                  }
                />
              </TitleValue>
              <TitleValue title={t("organization")} mt={4}>
                <ComponentToggle
                  showPrimary={
                    isEditable(Fields.Title) === false || oriolaUser === false
                  }
                  primaryComponent={
                    <Text variant="body1">{safeProject.customerName}</Text>
                  }
                  secondaryComponent={
                    <Box display="grid" gridRowGap="15px">
                      <Text variant="body1" noWrap>{`${oldCustomerName}, ${t(
                        "changeTo"
                      ).toLowerCase()}:`}</Text>
                      <AutoComplete
                        width="100%"
                        options={organizations}
                        loadingOptions={searchingOrganizations}
                        onOptionSelected={(event, value) =>
                          onOrganizationSelected(value)
                        }
                        onSearch={query => onSearchOrganizations(query)}
                        onInputChange={event => {
                          const value = String(event.target.value || "");
                          const searchValue = value.toLowerCase();
                          onSearchOrganizations(searchValue);
                        }}
                        getOptionSelected={(option, value) =>
                          option.name === value.name
                        }
                        getOptionLabel={option => getLabel(option)}
                        placeholder={t("writeOrganizationNameOrId")}
                        noOptionsText={t("noOrganizations")}
                        loadingText={t("loading")}
                        error={autoCompleteErrorAndText.error}
                        errorText={autoCompleteErrorAndText.errorText}
                        id="organizationAutoCompleteBox"
                      />
                    </Box>
                  }
                />
              </TitleValue>
              <TitleValue title={t("service")} mt={4}>
                <ComponentToggle
                  showPrimary={isEditable(Fields.Service) === false}
                  primaryComponent={
                    <Text variant="body1">{safeProject.service || "-"}</Text>
                  }
                  secondaryComponent={
                    <Dropdown
                      mt={1}
                      value={safeProject.service}
                      emptySelectionLabel={t("select")}
                      options={[defaultService]}
                      onSelectOption={value =>
                        dispatch(setProjectService(value))
                      }
                      showSelectNone={false}
                      error={validateFields.serviceError}
                      errorText={t("requiredField")}
                      inputProps={{ id: "serviceDropDown" }}
                    />
                  }
                />
              </TitleValue>
              <TitleValue title={t("description")} mt={4}>
                <ComponentToggle
                  showPrimary={isEditable(Fields.Description) === false}
                  primaryComponent={renderFormattedDescription()}
                  secondaryComponent={
                    <Input
                      disabled={creatingProject === true}
                      value={safeProject.description || ""}
                      mt={1}
                      placeholder={t("writeDescription")}
                      rows={5}
                      multiline
                      onChanged={value =>
                        dispatch(setProjectDescription(value))
                      }
                      id="description"
                    />
                  }
                />
              </TitleValue>
              <TitleValue
                title={t("sourceFiles")}
                mt={4}
                actions={
                  !isEditable(Fields.SourceFiles) &&
                  sourceAttachments.length > 1 && (
                    <Button
                      variant="secondary"
                      onClick={onDownloadAllSourceAttachmentsClick}
                    >
                      {t("downloadAll")}
                    </Button>
                  )
                }
              >
                {isEditable(Fields.SourceFiles) && (
                  <FileUpload
                    id="sourceFileUpload"
                    mt={1}
                    onFilesSelected={files =>
                      onFilesSelected(AttachmentType.SOURCE, files)
                    }
                    addFileImg={<AddFile />}
                    title={t("uploadFile")}
                    description={`${t("or").toLowerCase()} ${t(
                      "dragAndDrop"
                    ).toLowerCase()}`}
                  />
                )}
                <FileList
                  className={classes.fileList}
                  id="sourceFileList"
                  mt={1}
                  files={sourceAttachments.concat(newSourceAttachments)}
                  getFileOpts={attachment =>
                    getAttachmentFileOpts(
                      isEditable(Fields.SourceFiles),
                      attachment
                    )
                  }
                  onRightButtonClick={(e, attachment) =>
                    handleAttachmentClick(
                      isEditable(Fields.SourceFiles),
                      e,
                      attachment
                    )
                  }
                  attachmentImg={<Attachment style={{ width: "20px" }} />}
                  emptyListText={t("noAttachments")}
                />
              </TitleValue>
              {mobile === true && renderDeadlineStatusCreatedAt()}
            </Box>
            {mobile === false && renderDeadlineStatusCreatedAt()}
          </Box>
          {rendeSaveCancelAbortButtons("editProject")}
        </Box>
      </ContentContainer>
      <ContentContainer mt={3}>
        <Box
          height="70px"
          display="flex"
          flexDirection="row"
          alignItems="center"
          borderBottom={`2px solid ${OriolaColors.LightGrey}`}
          px={[1, 1, 1, 4, 4]}
        >
          <Box flexGrow={1}>
            <Text variant="h2">{t("results")}</Text>
          </Box>
          <Box>{renderTopButton("addResults")}</Box>
        </Box>
        <Box display="flex" m={[1, 1, 1, 4, 4]}>
          <Box width={mobile === false ? "75%" : "100%"}>
            <TitleValue title={t("coverLetter")}>
              <ComponentToggle
                showPrimary={isEditable(Fields.CoverLetter) === false}
                primaryComponent={
                  <Text whiteSpace="pre-wrap">
                    {safeProject.coverLetter || "-"}
                  </Text>
                }
                secondaryComponent={
                  <Input
                    disabled={creatingProject === true}
                    value={safeProject.coverLetter || ""}
                    mt={1}
                    placeholder={t("writeCoverLetter")}
                    rows={5}
                    multiline
                    onChanged={value => dispatch(setProjectCoverLetter(value))}
                    id="coverLetter"
                  />
                }
              />
            </TitleValue>
            <TitleValue
              title={t("resultFiles")}
              my={4}
              actions={
                !isEditable(Fields.ResultFiles) &&
                resultAttachments.length > 1 && (
                  <Button
                    variant="secondary"
                    onClick={onDownloadAllResultAttachmentsClick}
                  >
                    {t("downloadAll")}
                  </Button>
                )
              }
            >
              {isEditable(Fields.ResultFiles) && (
                <FileUpload
                  id="resultFileUpload"
                  mt={1}
                  onFilesSelected={files =>
                    onFilesSelected(AttachmentType.RESULT, files)
                  }
                  addFileImg={<AddFile />}
                  title={t("uploadFile")}
                  description={`${t("or").toLowerCase()} ${t(
                    "dragAndDrop"
                  ).toLowerCase()}`}
                />
              )}
              <FileList
                id="resultFileList"
                mt={1}
                files={resultAttachments.concat(newResultAttachments)}
                getFileOpts={attachment =>
                  getAttachmentFileOpts(
                    isEditable(Fields.ResultFiles),
                    attachment
                  )
                }
                onRightButtonClick={(e, attachment) =>
                  handleAttachmentClick(
                    isEditable(Fields.ResultFiles),
                    e,
                    attachment
                  )
                }
                attachmentImg={<Attachment />}
                emptyListText={t("noAttachments")}
              />
            </TitleValue>
          </Box>
        </Box>
        {rendeSaveCancelAbortButtons("addResults")}
      </ContentContainer>
    </Box>
  );
}

Project.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }),
  match: PropTypes.shape({
    params: PropTypes.shape({ projectId: PropTypes.string }),
  }),
};
Project.defaultProps = {
  history: {},
  match: {},
};

export default withRouter(Project);
