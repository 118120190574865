import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
// eslint-disable-next-line import/no-cycle
import { OrigoHeader } from "..";

function Layout({ children }) {
  return (
    <Box>
      <OrigoHeader />
      <Box>{children}</Box>
    </Box>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
};

Layout.defaultProps = {
  children: null,
};

export default Layout;
