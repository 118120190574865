import moment from "moment";

const formatDeadlineDate = value => {
  if (value && moment(value).isValid()) {
    // disregard time and timezone
    const dateWithoutTime = value.substring(0, 10);
    return moment(dateWithoutTime).format("DD.MM.YYYY");
  }
  return "";
};

export default formatDeadlineDate;
