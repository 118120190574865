import React from "react";
import { Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { OrigoAuthenticator } from "@oriola-origo/origo-ui-core";
import { rest as RestService } from "@oriola-origo/origo-common-client-lib";
import moment from "moment";
import {
  Layout,
  Projects,
  Project,
  CreateProject,
  NotFound,
  ShowOnlyInDevelopment,
  UserInfo,
  ProtectedRoute,
} from "./components";
import {
  userSignIn,
  fetchUserDataFromOrigo,
  userSignOut,
  setTokenData,
} from "./redux/reducers";
import "./App.css";
import { Permissions } from "./utils/auth/permissions";

function App() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const user = useSelector(state => state.user);
  const { userData } = user;

  // initialize axios iintercepter
  if (user.tokenData && user.sessionId) {
    RestService.init(user, res => dispatch(setTokenData(res)));
  }

  const initialize = signInData => {
    // set signed in
    dispatch(userSignIn(signInData));

    // update user data from origo
    const { userId } = signInData.userData;
    dispatch(fetchUserDataFromOrigo(userId)).then(data => {
      if (data && data.language) {
        i18n.changeLanguage(data.language);
        moment.locale(data.language);
      }
    });
  };

  const renderApp = () => (
    <Layout>
      <Switch>
        <ProtectedRoute
          path="/"
          component={Projects}
          failComponent={NotFound}
          exact
          userData={userData}
          permission={Permissions.VIEW_PROJECTS}
        />
        <ProtectedRoute
          path="/project/:projectId"
          component={Project}
          failComponent={NotFound}
          exact
          userData={userData}
          permission={Permissions.VIEW_PROJECTS}
        />
        <ProtectedRoute
          path="/create"
          component={CreateProject}
          failComponent={NotFound}
          exact
          userData={userData}
          permission={Permissions.CREATE_PROJECT}
        />
        <ShowOnlyInDevelopment isRoute path="/user">
          <Route exact path="/user" component={UserInfo} />
        </ShowOnlyInDevelopment>
        <Route component={NotFound} />
      </Switch>
    </Layout>
  );

  return (
    <OrigoAuthenticator
      isSignedIn={user.signedIn}
      onSignedIn={signInData => initialize(signInData)}
      onSignedOut={() => {
        // clear
        dispatch(userSignOut());
      }}
    >
      {renderApp()}
    </OrigoAuthenticator>
  );
}

export default App;
