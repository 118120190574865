import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { Button, Progress } from "@oriola-origo/origo-ui-core";
import { OriolaColors } from "../../../theme";
// eslint-disable-next-line import/no-cycle
import { Text } from "../..";

function ButtonGroup({
  disabled,
  onLeftClick,
  onRightClick,
  leftText,
  rightText,
  leftClassName,
  rightClassName,
  leftId,
  rightId,
  showProgress,
  ...rest
}) {
  return (
    <Box display="flex" flexDirection="row" alignItems="center" {...rest}>
      <Button
        disabled={disabled}
        onClick={onLeftClick}
        className={leftClassName}
        id={leftId}
      >
        {showProgress === false && (
          <Text mx={2} color={OriolaColors.White}>
            {leftText}
          </Text>
        )}
        {showProgress === true && (
          <Progress mx={3} show size={25} color={OriolaColors.White} />
        )}
      </Button>
      <Box ml={1}>
        <Button
          variant="secondary"
          disabled={disabled}
          onClick={onRightClick}
          className={rightClassName}
          id={rightId}
        >
          <Text mx={2}>{rightText}</Text>
        </Button>
      </Box>
    </Box>
  );
}

ButtonGroup.propTypes = {
  disabled: PropTypes.bool,
  onLeftClick: PropTypes.func,
  onRightClick: PropTypes.func,
  leftText: PropTypes.string,
  rightText: PropTypes.string,
  showProgress: PropTypes.bool,
  leftClassName: PropTypes.string,
  rightClassName: PropTypes.string,
  leftId: PropTypes.string,
  rightId: PropTypes.string,
};

ButtonGroup.defaultProps = {
  disabled: false,
  onLeftClick: () => {},
  onRightClick: () => {},
  leftText: "ok",
  rightText: "cancel",
  showProgress: false,
  leftClassName: null,
  rightClassName: null,
  leftId: null,
  rightId: null,
};

export default ButtonGroup;
