import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import { ensureTrailingSlash } from "../../utils/url/url";

const origoUrl = ensureTrailingSlash(process.env.REACT_APP_ORIGO_URL);

function OrigoHeader() {
  const { tokenData } = useSelector(state => state.user);
  const [loaded, setLoaded] = useState(false);

  let divRef;
  const setRef = ref => {
    divRef = ref;
  };

  // Force refresh if token changes
  useEffect(() => {
    setLoaded(false);
  }, [tokenData]);

  useEffect(() => {
    if (!loaded) {
      const script = document.createElement("script");
      script.src = `${origoUrl}/integration/origo.js`;
      script.async = true;
      divRef.appendChild(script);
      script.onload = () => {
        setLoaded(true);
      };
    }
  }, [tokenData, setLoaded, divRef, loaded]);

  const { Origo } = window;
  return (
    <div ref={setRef}>
      {loaded && (
        <Origo.Header
          accessToken={tokenData.accessToken}
          onOrganizationChange={() => {}}
        />
      )}
      {!loaded && <Box height="69px" width="100%" />}
    </div>
  );
}

OrigoHeader.propTypes = {
  history: PropTypes.shape({}),
};

OrigoHeader.defaultProps = {
  history: {},
};

OrigoHeader.defaultProps = {};

export default OrigoHeader;
