import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { OriolaColors } from "../../../theme";

function NaviRibbon({ items, onClick, ...rest }) {
  const separator = "/";

  return (
    <Box display="flex" alignItems="center" {...rest}>
      {items.map((item, index) => {
        const lastItem = index === items.length - 1;
        const cursorStyle = lastItem === true ? null : { cursor: "pointer" };
        const clickHandler = lastItem === true ? () => {} : onClick;
        return (
          <Box key={item.id} display="flex" alignItems="center">
            <Box
              color={OriolaColors.DarkGrey}
              style={cursorStyle}
              fontSize="body1.fontSize"
              onClick={() => clickHandler(item.id)}
            >
              {item.value}
            </Box>
            {lastItem === false && (
              <Box
                fontSize="body1.fontSize"
                color={OriolaColors.DarkGrey}
                mx={1}
              >
                {separator}
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
}

NaviRibbon.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  props: PropTypes.shape({}),
  onClick: PropTypes.func,
};

NaviRibbon.defaultProps = {
  onClick: () => {},
  items: [],
  props: {},
};

export default NaviRibbon;
