import moment from "moment";
import { rest as RestService } from "@oriola-origo/origo-common-client-lib";
import { ensureTrailingSlash } from "../../../utils/url/url";

export const User = Object.freeze({
  USER_SIGN_IN: "USER_SIGN_IN",
  USER_SIGN_OUT: "USER_SIGN_OUT",
  SET_TOKEN_DATA: "USER_SET_TOKEN_DATA",
  UPDATE_USER_DATA: "UPDATE_USER_DATA",
  UPDATE_SELECTED_ORGANIZATION_ID: "UPDATE_SELECTED_ORGANIZATION_ID",
});

const origoUrl = ensureTrailingSlash(process.env.REACT_APP_ORIGO_URL);

// -- ACTIONS --

export const isTokenExpired = tokenData => {
  if (tokenData === null) {
    return true;
  }

  const { expiryTime } = tokenData;
  return moment().isAfter(expiryTime);
};

export const setTokenData = tokenData => ({
  type: User.SET_TOKEN_DATA,
  payload: tokenData,
});

export const getTokens = () => async (dispatch, getState) => {
  // get current token data
  const { tokenData } = getState().user;
  // check if expired
  if (isTokenExpired(tokenData)) {
    // refresh token data
    const { sessionId } = getState().user;
    const path = `${origoUrl}tokens`;
    const result = await RestService.getToken(sessionId, path);
    dispatch(setTokenData(result));

    // return token
    return Promise.resolve({
      idToken: result.idToken,
      accessToken: result.accessToken,
    });
  }

  // return token directly since it is still valid
  return Promise.resolve({
    idToken: tokenData.idToken,
    accessToken: tokenData.accessToken,
  });
};

export const userSignOut = () => ({
  type: User.USER_SIGN_OUT,
  payload: { analytics: {} },
});

export const userSignIn = signInData => ({
  type: User.USER_SIGN_IN,
  payload: {
    signInData,
    analytics: {
      signInData,
    },
  },
});

export const updateUserData = userData => ({
  type: User.UPDATE_USER_DATA,
  payload: userData,
});

// eslint-disable-next-line consistent-return
export const fetchUserDataFromOrigo = userId => async dispatch => {
  try {
    const path = `${origoUrl}api/v1/user/${userId}`;
    // override and use ACCESS TOKEN instead of ID TOKEN that the API uses
    const tokens = await dispatch(getTokens());
    const config = RestService.createConfig(tokens.accessToken);
    const origoUserData = await RestService.get(path, config);

    dispatch(
      updateUserData({
        phoneNumber: origoUserData.phoneNumber,
        language: origoUserData.language,
        organizations: origoUserData.organizations,
      })
    );
    return origoUserData;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const setSelectedOrganizationId = organizationId => ({
  type: User.UPDATE_SELECTED_ORGANIZATION_ID,
  payload: organizationId,
});

// -- REDUCER --

const INIT_STATE = {
  userData: {},
  signedIn: false,
  sessionId: null,
  tokenData: null,
  selectedOrganizationId: null,
};

// eslint-disable-next-line default-param-last
export const userReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case User.SET_TOKEN_DATA:
      return { ...state, tokenData: action.payload };
    case User.USER_SIGN_OUT:
      return {
        ...state,
        signedIn: false,
        sessionId: null,
        tokenData: null,
        userData: null,
      };
    case User.USER_SIGN_IN: {
      const { sessionId, userData, tokenData } = action.payload.signInData;
      return { ...state, sessionId, userData, tokenData, signedIn: true };
    }
    case User.UPDATE_USER_DATA:
      return { ...state, userData: { ...state.userData, ...action.payload } };
    case User.UPDATE_SELECTED_ORGANIZATION_ID:
      return { ...state, selectedOrganizationId: action.payload };
    default:
      return state;
  }
};
