import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { AutoSizer, InfiniteLoader, List } from "react-virtualized";
import { Progress } from "@oriola-origo/origo-ui-core";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& a": {
      textDecoration: "none",
    },
  },
  list: {
    outline: "none",
    overflowX: "hidden !important",
    // InnerScrollContainer must use inherit width to ignore the scaling issues caused by scrollbar width variation.
    "& .ReactVirtualized__Grid__innerScrollContainer": {
      width: "inherit !important",
    },
  },
  progress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    bottom: theme.spacing(1),
    left: "50%",
  },
}));

function InfinityList({
  items,
  visibleItemCount,
  itemOverallCount,
  rowHeight,
  rowSpacing,
  getRowHeight,
  isLoading,
  onRenderRow,
  onLoad,
  loadThreshold,
}) {
  const overallRowHeight = rowHeight + rowSpacing;
  const listHeight = overallRowHeight * visibleItemCount;
  const onLoadProducts = isLoading ? () => {} : onLoad;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div style={{ height: listHeight, width: "100%" }}>
        <InfiniteLoader
          isRowLoaded={({ index }) => !!items[index]}
          loadMoreRows={({ startIndex, stopIndex }) =>
            onLoadProducts(startIndex, stopIndex)
          }
          rowCount={itemOverallCount || 1000}
          minimumBatchSize={visibleItemCount}
          threshold={loadThreshold}
        >
          {({ onRowsRendered, registerChild }) => (
            <AutoSizer>
              {({ width, height }) => (
                <List
                  className={classes.list}
                  height={height}
                  width={width}
                  onRowsRendered={onRowsRendered}
                  ref={registerChild}
                  rowCount={items.length}
                  rowHeight={getRowHeight || overallRowHeight}
                  rowRenderer={onRenderRow}
                />
              )}
            </AutoSizer>
          )}
        </InfiniteLoader>
        <div className={classes.progress}>
          <Progress size={30} show={isLoading} />
        </div>
      </div>
    </div>
  );
}

InfinityList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onRenderRow: PropTypes.func.isRequired,
  rowHeight: PropTypes.number,
  getRowHeight: PropTypes.func,
  rowSpacing: PropTypes.number,
  onLoad: PropTypes.func,
  visibleItemCount: PropTypes.number,
  itemOverallCount: PropTypes.number,
  isLoading: PropTypes.bool,
  loadThreshold: PropTypes.number,
};

InfinityList.defaultProps = {
  onLoad: () => {},
  rowHeight: 60,
  rowSpacing: 0,
  visibleItemCount: 10,
  itemOverallCount: 10,
  isLoading: false,
  getRowHeight: null,
  loadThreshold: 15,
};

export default InfinityList;
